import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import { getServerUrl, getLongLanguageCode, isJsonString } from '../../services/utils';
import api from '../../services/api';
import { Button, ButtonBlock, PrintButton, Loader } from '../../atoms';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import {
  CardBlock,
  Error,
  Header,
  Modal,
  Note,
  Success,
  ThirdPartySystemErrorWarning,
  TitleWithIcon,
  PaymentModal,
} from '../../molecules';
import CreditCardImg from '../../assets/images/credit_card.png';
import AmericanExpress from '../../assets/images/Latest_American_Express.png';
import Interac from '../../assets/images/card_interac.png';
import MasterCard from '../../assets/images/mastercard_new.png';
import VisaCardImg from '../../assets/images/Visa_latest.png';
import Sears from '../../assets/images/Sears.png';
import MonerisVault from '../../assets/images/MonerisVault.png';
import MonerisToken from '../../assets/images/MonerisToken.png';
import Master from '../../assets/images/Master.png';
import Jcb from '../../assets/images/Jcb.png';
import Discover from '../../assets/images/Discover.png';
import Unipay from '../../assets/images/Unipay.png';
import './savedPaymentMethod.scss';

const SavedPaymentMethod0 = ({
  fields,
  component,
  setComponent,
  toNextStep,
  error,
  errorContent,
  isSuccess,
  t,
  isQueryParams,
  thirdPartySystemErrorType,
  setIsError,
  maxPaymentThresholdReached,
  setMaxPaymentThresholdReached,
}) => {
  const userInfo = useSelector((s) => s.user);
  const configKeys = useSelector((config) => config.settings.configKeys);
  const location = useLocation();
  const { visibleId } = useSelector((reduxState) => reduxState.user.selectedCard);

  const returnUrl = `${getServerUrl()}${location.pathname}`;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState('');
  const [isGetTicketFetching, setIsGetTicketFetching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isRemoveCardModalOpen, setIsRemoveCardModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState('');
  const [isGetTicketFetchError, setIsGetTicketFetchError] = useState('');
  const [addPaymentError, setAddPaymentError] = useState('');
  const [addPaymentSuccess, setAddPaymentSuccess] = useState(false);

  let alreadyHavePaymentMethods;

  const postPaymentMethodForm = async () => {
    document.getElementById('paymentMethod').submit();
  };
  const updatepostPaymentMethodForm = async () => {
    document.getElementById('updatepaymentMethod').submit();
  };

  if (component.paymenMethodsList.length > 0) {
    alreadyHavePaymentMethods = true;
  } else {
    alreadyHavePaymentMethods = false;
  }

  const checkUsageOfPaymentMean = () => {
    api
      .getSubscriptionsCount({
        visibleId: userInfo.selectedCard.visibleId,
        customerId: userInfo.customerId,
        regFormOfPaymRecId: component.paymenMethodsList[0].RegFormOfPaymRecId,
      })
      .then((response) => {
        if (response.data.Success) {
          if (response.data.Count > 0) {
            setIsModalOpen(true);
          } else {
            toNextStep();
          }
        }
      })
      .catch(() => { });
  };

  const monerisEnvironment =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.Environment').map((config) => config.setting);

  useEffect(() => {
    api
      .getAccessToken()
      .then((accessToken) => setToken(accessToken))
      .catch(() => {
        setToken('');
      });
  }, []);

  const resultBlock = isSuccess ? (
    <div className="saved-payment-success">
      <Success
        title={fields.paymentMethodAddedSuccess}
        titleA11y={fields.paymentMethodAddedSuccessA11y.value}
      />
    </div>
  ) : (
    <div className="saved-payment-error">
      <Error
        title={errorContent ? errorContent : fields.paymentMethodAddedError.value}
        titleA11y={fields.paymentMethodAddedErrorA11y.value}
      />
    </div>
  );

  const getRegisterPaymentMeansForCustomer = () => {
    api
      .getRegisterPaymentMeansForCustomer({
        VisibleId: userInfo.selectedCard.visibleId,
        CustomerId: userInfo.customerId,
      })
      .then((response) => {
        if (response) {
          if (response.data.Success === false) {
            handleServerSuccessError(response.data.Error, setIsError);
            setIsloading(false);
          } else if (response.data.Success) {
            setComponent({
              paymenMethodsList: response.data.RegisterPaymentMeansList,
            });
            if (
              response.data.RegisterPaymentMeansList.filter((key) => key.AccountType == 1).length >=
              3
            ) {
              setMaxPaymentThresholdReached(true);
              setIsloading(false);
            } else {
              setMaxPaymentThresholdReached(false);
              setIsloading(false);
            }
            setIsloading(false);
          }
        }
      });
  };

  const handleCheckoutModal = () => {
    setIsGetTicketFetchError('');
    setAddPaymentError('');
    if (maxPaymentThresholdReached === true) {
      setIsModalOpen(true);
    } else {
      setIsloading(true);
      setIsGetTicketFetching(true);
      api
        .getTicket({
          PaymentProfileType: 'TOKENIZE',
          OrderNumber: '',
          CustomerId: userInfo.customerId,
          VisibleId: visibleId,
          userName: userInfo.userName,
          Amount: 0,
        })
        .then((response) => {
          if (response.data.Success === true) {
            setIsloading(false);
            setIsOpen(true);
            var myCheckout = new window.monerisCheckout();
            myCheckout.setMode(monerisEnvironment[0]);
            myCheckout.setCheckoutDiv('monerisCheckout');
            myCheckout.setCallback('page_loaded', myPageLoad);
            myCheckout.setCallback('cancel_transaction', myCancelTransaction);
            myCheckout.setCallback('error_event', myErrorEvent);
            myCheckout.setCallback('payment_receipt', myPaymentReceipt);
            myCheckout.setCallback('payment_complete', myPaymentComplete);
            myCheckout.startCheckout(response.data.TicketResponse.ticket);

            const ticketResponse = response.data.TicketResponse.ticket;

            function myPageLoad() {
              setIsGetTicketFetching(false);
            }

            function myCancelTransaction() {
              setIsOpen(false);
            }

            function myPaymentComplete(args) {
              document.getElementById('monerisCheckout').innerHTML = '';
              document.getElementById('monerisCheckout').style = '';
              const parsedData = isJsonString(args) ? JSON.parse(args) : '';
              if (parsedData?.response_code !== '001') {
                setIsOpen(false);
                setAddPaymentError(t('monerisPaymentError'));
              } else {
                setIsOpen(false);
                setIsloading(true);
                api
                  .createRegisteredPaymentMean({
                    PaymentProfileType: 'TOKENIZE',
                    Ticket: ticketResponse,
                    VisibleId: visibleId,
                    CustomerId: userInfo.customerId,
                    Username: userInfo.userName,
                  })
                  .then((response) => {
                    if (response.data.Success === true) {
                      getRegisterPaymentMeansForCustomer();
                      setAddPaymentSuccess(true);
                    } else {
                      setIsloading(false);
                      setAddPaymentSuccess(false);
                      const parsedError = isJsonString(response?.data?.Error)
                        ? JSON.parse(response.data.Error)
                        : '';
                      const data = JSON.parse(response.data.Error);
                      if (data.Code === 'AFMS-CRT-FE-0069') {
                        setAddPaymentError(fields.paymentMethodAddedError.value);
                      } else {
                        setAddPaymentError(parsedError?.Description);
                      }
                    }
                  });
              }
            }

            function myPaymentReceipt(args) {
              myPaymentComplete(args);
            }

            function myErrorEvent() {
              setIsOpen(false);
            }
          } else {
            setIsloading(false);
            setIsGetTicketFetching(false);
            setIsOpen(false);
            const parsedError = isJsonString(response?.data?.Error)
              ? JSON.parse(response.data.Error)
              : '';
            setIsGetTicketFetchError(parsedError?.Description);
          }
        })
        .catch(() => { });
    }
  };

  const removeSavedCard = (card) => {
    setIsRemoveCardModalOpen(false);
    setIsloading(true);
    setAddPaymentError('');
    api
      .terminateRegisterPaymentMean({
        customerId: userInfo.customerId,
        RegFormOfPaymRecId: card.RegFormOfPaymRecId,
        VisibleId: visibleId,
        Username: userInfo.userName,
        VaultId: card.CreditCard.VaultId,
      })
      .then((response) => {
        if (response.data.Success === true) {
          getRegisterPaymentMeansForCustomer();
          setAddPaymentSuccess(false);
        } else {
          setAddPaymentSuccess(false);
          setIsloading(false);
          const parsedError = isJsonString(response?.data?.Error)
            ? JSON.parse(response.data.Error)
            : '';
          setAddPaymentError(parsedError?.Description);
        }
      });
  };

  const getCardImage = (Type) => {
    let cardImage;
    let cardName;
    switch (Type.toLowerCase()) {
      case 0:
      case 'v':
      case 'visa':
        cardImage = VisaCardImg;
        cardName = 'visaCard';
        break;
      case 1:
      case 'm':
      case 'mastercard':
        cardImage = MasterCard;
        cardName = 'masterCard';
        break;
      case 2:
      case 'americanexpress':
        cardImage = AmericanExpress;
        cardName = 'amexCard';
        break;
      case 3:
      case 'no':
      case 'discover':
        cardImage = Discover;
        cardName = '';
        break;
      case 4:
      case 'monerisvault':
        cardImage = MonerisVault;
        cardName = '';
        break;
      case 5:
      case 'moneristoken':
        cardImage = MonerisToken;
        cardName = '';
        break;
      case 6:
      case 'interac':
        cardImage = Interac;
        cardName = 'interacCard';
        break;
      case 7:
      case 'master':
        cardImage = Master;
        cardName = 'masterCard';
        break;
      case 8:
      case 'ax':
      case 'amex':
        cardImage = AmericanExpress;
        cardName = 'amexCard';
        break;
      case 9:
      case 'unknown':
        cardImage = CreditCardImg;
        cardName = '';
        break;
      case 10:
        cardImage = CreditCardImg;
        cardName = '';
        break;
      case 11:
      case 'c1':
      case 'jcb':
        cardImage = Jcb;
        cardName = '';
        break;
      case 12:
      case 'd':
      case 'debit':
        cardImage = Interac;
        cardName = 'interacCard';
        break;
      case 13:
      case 'se':
      case 'sears':
        cardImage = Sears;
        cardName = '';
        break;
      case 14:
      case 'up':
      case 'unionpay':
        cardImage = Unipay;
        cardName = '';
        break;
      default:
        cardImage = CreditCardImg;
        cardName = '';
    }
    return { cardImage, cardName };
  };

  return (
    <>
      {isLoading && <Loader />}
      <Modal
        title={fields?.maxPaymentTitle?.value}
        text={fields?.maxPaymentText?.value}
        textSubmit={fields.savedPaymentBackButton.value}
        onSubmit={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Modal
        title={fields?.removePaymentTitle?.value}
        text={fields?.removePaymentText?.value}
        paragraph={<RichText field={fields?.removePaymentText1} />}
        textCancel={fields.savedPaymentBackButton.value}
        textSubmit={fields.savedPaymentConfirmButton.value}
        onSubmit={() => {
          removeSavedCard(selectedCard);
        }}
        isModalOpen={isRemoveCardModalOpen}
        setIsModalOpen={setIsRemoveCardModalOpen}
        customCss={false}
      />
      <PaymentModal
        title={fields?.paymentDetailsTitle?.value}
        cautionMessage={t('monerisPaymentCautionMessage')}
        isModalOpen={isOpen}
        setIsModalOpen={setIsOpen}
        isGetTicketFetching={isGetTicketFetching}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      />

      <Header>
        <Text field={fields.heading} />
      </Header>

      {thirdPartySystemErrorType ? (
        <>
          <ThirdPartySystemErrorWarning {...{ fields, errorCode: thirdPartySystemErrorType }} />
        </>
      ) : (
        <>
          {error ? <Error small title={error} /> : null}
          {isGetTicketFetchError ? <Error small title={isGetTicketFetchError} /> : null}
          {addPaymentError ? (
            <Error
              small
              showfailedIcon={
                addPaymentError === fields.paymentMethodAddedError.value ? true : false
              }
              title={addPaymentError}
            />
          ) : null}
          {isQueryParams ? resultBlock : null}
          {addPaymentSuccess ? (
            <div className="saved-payment-success">
              <Success
                title={fields.paymentMethodAddedSuccess}
                titleA11y={fields.paymentMethodAddedSuccessA11y.value}
              />
            </div>
          ) : null}

          <CardBlock title={fields.title.value}>
            <RichText field={fields.description} />
            {component &&
              component.paymenMethodsList &&
              component.paymenMethodsList.map((card, index) => {
                if (card.AccountType === 1) {
                  return (
                    <>
                      <div className="saved-card-container">
                        <TitleWithIcon
                          title={`**** ${card.CreditCard.TruncatedPAN.slice(-4)}`}
                          MediaImageSrc={getCardImage(card.CreditCard.CardType)?.cardImage}
                          className="saved-card-title"
                          isFromSPM
                          cardAltText={getCardImage(card.CreditCard.CardType)?.cardName}
                        ></TitleWithIcon>
                        <button
                          className="remove-card"
                          onClick={() => {
                            setSelectedCard(card);
                            setIsRemoveCardModalOpen(true);
                          }}
                        >
                          <Text field={fields.removeButtonText} />
                        </button>
                      </div>
                      <div>{index !== component.paymenMethodsList.length - 1 && <hr />}</div>
                    </>
                  );
                }
                if (card.AccountType === 0) {
                  return null;
                }
              })}
            <ButtonBlock>
              <ButtonBlock right>
                {maxPaymentThresholdReached === true ? (
                  <button
                    className="max-cards-reached-btn"
                    onClick={handleCheckoutModal}
                    type="submit"
                    aria-label={fields.maxPaymentTitle1A11y.value}
                    tabIndex="0"
                  >
                    <Text field={fields.maxPaymentTitle1} />
                  </button>
                ) : (
                  <Button
                    type="submit"
                    buttonTextA11y={fields.addButtonTextA11y.value}
                    onClick={handleCheckoutModal}
                  >
                    <Text field={fields.addButtonText} />
                  </Button>
                )}
              </ButtonBlock>
            </ButtonBlock>
          </CardBlock>

          {isSuccess ? (
            <>
              <Note noteA11y={fields.paymentMethodNoteA11y.value} withIcon>
                <RichText field={fields.paymentMethodNote} />
              </Note>
              <ButtonBlock>
                <PrintButton />
              </ButtonBlock>
            </>
          ) : null}

          {configKeys &&
            token &&
            configKeys
              .filter((item) => item.key === 'Cpg.Url')
              .map((configKey) => (
                <form
                  method="POST"
                  id="paymentMethod"
                  name="paymentMethod"
                  style={{ display: 'none' }}
                  action={configKey.setting}
                >
                  <input type="hidden" name="cc_crypt_type" value="7" />
                  <input type="hidden" name="lang" id="lang" value={getLongLanguageCode()} />
                  <input
                    type="hidden"
                    name="LANGUAGE"
                    id="LANGUAGE"
                    value={getLongLanguageCode()}
                  />
                  <input type="hidden" name="cust_id" id="cust_id" value={userInfo.customerId} />
                  <input type="hidden" name="rvarIsAx" value="true" />
                  <input type="hidden" name="rvarName" value="" />
                  <input
                    type="hidden"
                    name="ResponseURL"
                    id="responseURL"
                    value={`${getServerUrl()}/apidata/Sales/CreatePaymentMean?token=${token}&returnUrl=${returnUrl}&deviceId=${userInfo.sessionInstanceId
                      }`}
                  />
                  <input type="hidden" name="isRegisteredPaymentMean" value="true" />
                </form>
              ))}
        </>
      )}
    </>
  );
};

export default withTranslation()(SavedPaymentMethod0);
