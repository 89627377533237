import React, { useEffect, useState } from 'react';
import { Text, RichText, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';
import {
  StepTracker,
  TextInput,
  CardBlock,
  Header,
  Select,
  Note,
  Modal,
  Title,
  Success,
} from '../../molecules';
import sharedFormConstants from '../../constants/sharedFormConstants';
import { Formik } from 'formik';
import * as Yup from 'yup';
import api from '../../services/api';
import { Loader, Button, Checkbox, InlineError } from '../../atoms';
import { setUserPersonalInfo, setFirstNamePersonalInfo, setB2cClaimsResponse } from '../../redux/user/actions';
import './b2cPersonalInformation.scss';
import localStorageKeys from '../../constants/localStorageKeys';
import {
  localstorageService,
  getQueryParams,
  redirectToUrl,
  resetAccessTokenValidity,
} from '../../services/utils';
import {
  changeUserStatus,
  itemsAreLoading,
  fetchAllData,
  fetchAllDataSuccessfullyLoaded,
  fetchAvailableSettings,
  setWeakAccount,
  setGuestUserEnabled,
  anonymousSaveAccessToken,
} from '../../redux/user/actions';
import handleServerError from '../../services/handleServerError';

const B2CPersonalInformation = (props) => {
  const [options, setOptions] = useState([]);
  const [countryData, setCountryData] = useState('');
  const Countries = [{ label: 'Canada', value: 'CAN' }];
  const [emailChecked, setEmailChecked] = useState(true);
  const [contentchecked, setContentChecked] = useState(false);
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [postalCode, setPostalCode] = useState('');
  const [accountCreation, setAccountCreation] = useState(false);
  const dispatch = useDispatch();
  const [startedLogin, setStartedLogin] = useState(false);
  const [error, setError] = useState('');

  const customerId = useSelector((state) => state.user.customerId);
  const loginEmail = useSelector((state) => state.user.loginEmail);
  const authenticated = useSelector((state) => state.user.authenticated);
  const anonymous = useSelector((state) => state.user.anonymous);
  const prestoCardCustomerId = useSelector((reduxState) => reduxState.user.customerId);
  // const step = 2;

  useEffect(() => {
    setStep(step + 2);
    api
      .getClaims()
      .then((res) => {
        //console.log(res);
        if (res) {
          setStartedLogin(true);
          localstorageService().setItem(localStorageKeys.accessToken, res.data.Data.Access_token);
          localstorageService().setItem(localStorageKeys.refreshToken, res.data.Data.Access_token);
          localstorageService().setItem(
            localStorageKeys.accessTokenLifetime,
            res.data.Data.Expires_in
          );
          dispatch(
            setB2cClaimsResponse({
              IsMfAEnabled: String(res?.data?.Data?.IsMfAEnabled).toLowerCase() === 'true',
              MFANotificationLastViewedDate: res?.data?.Data?.MFANotificationLastViewedDate,
              PasswordNotificationLastViewedDate:
                res?.data?.Data?.PasswordNotificationLastViewedDate,
              IsFirstTimeLogin: String(res?.data?.Data?.IsFirstTimeLogin).toLowerCase() === 'true',
              PhoneNumber: res?.data?.Data?.PhoneNumber,
            })
          );
          if (
            prestoCardCustomerId !== null &&
            prestoCardCustomerId !== undefined &&
            anonymous &&
            !authenticated
          ) {
            api
              .associateCustomerAddCard({
                CustomerId: res.data.Data.CustomerId,
                CardId: prestoCardCustomerId,
              })
              .then((res) => {
                dispatch(changeUserStatus(true));
                dispatch(fetchAllData());
                dispatch(setGuestUserEnabled({ isGuestLogin: false }));
                getCountryData();
              });
          } else {
            dispatch(fetchAllData());
            dispatch(setGuestUserEnabled({ isGuestLogin: false }));
            getCountryData();
            dispatch(changeUserStatus(false));
          }
        }
      })
      .catch((res) => {
        dispatch(itemsAreLoading(false));
        //(signInFormFields.googleToken, 1);
        handleServerError(undefined, setError)(res);
      });
  }, []);

  useEffect(() => {
    countryData &&
      countryData?.Areas &&
      countryData.Areas.map((data) => {
        let obj = {
          label: data.Name,
          value: data.Id,
        };
        options.push(obj);
      });
  }, [countryData]);

  const getCountryData = () => {
    setIsLoading(true);
    api
      .getCountryAreas()
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          setCountryData(response?.data);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const onEmailCheckedChange = (e) => {
    if (e) {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
    setEmailChecked(e);
  };

  const onPostalCodeChange = (e) => {
    setPostalCode(e.target.value.toUpperCase());
  };

  const accCreationSuccessScreen = () => {
    return (
      <div className="account-creation-success-screen">
        <Success
          title={{ value: props.fields.AccountCreationSuccess.value }}
          Content={() => (
            <div className="b2caccountcreation-success-content">
              <Text field={props.fields.AccountCreationSuccessDesc} />
              <div className="b2c-gotodashboard-button">
                <Button onClick={() => api.dashboard()}>
                  <Text field={props.fields.GoToDashboardLabel} />
                </Button>
              </div>
            </div>
          )}
        />
      </div>
    );
  };
  // !accountCreation ?: (
  //   accCreationSuccessScreen()
  // )
  return (
    <div>
      {isLoading && <Loader />}
      <div className="b2cPersonalInformation">
        <StepTracker
          activeIndex={step}
          items={[
            {
              text: <Text field={props.fields.EmailIDStep} />,
              textInProgressA11y: props.fields.Step1InProgressA11y.value,
              textCompletedA11y: props.fields.Step1CompletedA11y.value,
            },
            {
              text: <Text field={props.fields.PasswordStep} />,
              textNotCompletedA11y: props.fields.Step2NotCompletedA11y.value,
              textInProgressA11y: props.fields.Step2InProgressA11y.value,
              textCompletedA11y: props.fields.Step2CompletedA11y.value,
            },
            {
              text: <Text field={props.fields.UserInformationStep} />,
              textNotCompletedA11y: props.fields.Step3NotCompletedA11y.value,
              textCompletedA11y: props.fields.Step3CompletedA11y.value,
              inProgress: !accountCreation ? true : false,
            },
          ]}
        />
        {!accountCreation ? (
          <div>
            <Header centered aria-label={props.fields.UserInformationTitleA11y.value}>
              <Text field={props.fields.UserInformationTitle} />
            </Header>
            <div className="usersub-info">
              <Text field={props.fields.UserInfoSubText} />
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={{
                FirstName: '',
                LastName: '',
                StreetAdress: '',
                AptSuiteBuilding: '',
                City: '',
                Province:
                  options &&
                  options.length > 0 &&
                  options
                    .filter((e) => {
                      return e.label === 'Ontario';
                    })
                    .map((val) => {
                      return val.value;
                    })[0],

                Country: '',
                PostalCode: '',
                PrimaryPhoneNo: '',
                SecondaryPhoneNo: '',
                // [sharedFormConstants.terms]: false,
              }}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                FirstName: Yup.string()
                  .required(props.fields.FirstNameError.value)
                  .max(25, props.fields.FirstNameLength.value)
                  .matches(/^[a-zA-Z0-9àâäæèéêëîïôœùûüÿçÀÂÄÆÈÉÊËÎÏÔŒÙÛÜŸÇ ,.'-]{1,25}$/, props.fields.FirstNameLength.value),
                LastName: Yup.string()
                  .required(props.fields.LastNameError.value)
                  .max(25, props.fields.LastNameLength.value)
                  .matches(/^[a-zA-Z0-9àâäæèéêëîïôœùûüÿçÀÂÄÆÈÉÊËÎÏÔŒÙÛÜŸÇ ,.'-]{1,25}$/, props.fields.LastNameLength.value),
                StreetAdress: Yup.string()
                  .required(props.fields.StreetAddressError.value)
                  .max(50, props.fields.StreetAddressLength.value)
                  .matches(/^.[a-zA-Z0-9\\@/#*!^?.=: -]+$/, props.fields.StreetAddressLength.value),
                AptSuiteBuilding: Yup.string()
                  .max(50, props.fields.ApartmentLength.value)
                  .matches(/^.[a-zA-Z0-9\\@/#*!^?.=: -]+$/, props.fields.ApartmentLength.value),
                City: Yup.string()
                  .required(props.fields.CityError.value)
                  .max(50, props.fields.CityLength.value)
                  .matches(/^[A-Za-z0-9 ]+$/, props.fields.CityLength.value),
                PostalCode: Yup.string()
                  .required(props.fields.PostalCodeError.value)
                  .matches(
                    /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z]\s?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/,
                    props.fields.PostalCodeFormatError.value
                  ),
                Province: Yup.string().required(props.fields.ProvinceError.value),
                PrimaryPhoneNo: Yup.string()
                  .required(props.fields.PrimaryPhoneNumberError.value)
                  .matches(
                    /^\(?([0-9]{3})\)?[\s-●]?([0-9]{3})[\s-●]?([0-9]{4})$/,
                    props.fields.PrimaryPhoneNumberLength.value
                  ),
                SecondaryPhoneNo: Yup.string().matches(
                  /^\(?([0-9]{3})\)?[\s-●]?([0-9]{3})[\s-●]?([0-9]{4})$/,
                  props.fields.SecondaryPhoneNumberError.value
                ),
                // [sharedFormConstants.terms]: Yup.boolean().oneOf(
                //   [true],
                //   props.fields.TermsandConditonsError.value
                // ),
              })}
              onSubmit={(values) => {
                // setIsLoading(true);
                let payload = {
                  // CustomerId: user?.customerId,
                  FirstName: values.FirstName,
                  LastName: values.LastName,
                  StreetAddress: values.StreetAdress,
                  Apartment: values.AptSuiteBuilding,
                  AreaId: values.Province,
                  AreaName: options
                    .filter((e) => {
                      return e.value === values.Province;
                    })
                    .map((val) => {
                      return val.label;
                    })[0],
                  AreaCode: values.Province,
                  CountryId: 'CAN',
                  City: values.City,
                  PostalCode: values.PostalCode,
                  StreetAddress: values.StreetAdress,
                  Apartment: values.AptSuiteBuilding,
                  Province: values.Province,
                  CountryName: 'Canada',
                  PrimaryPhone: values.PrimaryPhoneNo,
                  SecondaryPhone: values.SecondaryPhoneNo,
                  TraxOptInOut: emailChecked ? 'on' : 'off',
                  MarktOptInOut: contentchecked ? 'on' : 'off',
                  Email: loginEmail,
                  CustomerId: customerId,
                };
                api
                  .updatePersonalData(payload)
                  .then((response) => {
                    //console.log(response);
                    if (response?.data?.Success) {
                      dispatch(changeUserStatus(true));
                      dispatch(fetchAllData());
                      dispatch(setGuestUserEnabled({ isGuestLogin: false }));
                      dispatch(setFirstNamePersonalInfo(response.data.UpdatedPersonalData.Name.FirstName));

                      dispatch(
                        setUserPersonalInfo({
                          updatePersonalInfoEnabled: false,
                          userPersonalInfo: {},
                          submittedInfo: { submit: true, success: true },
                        })
                      );
                      setAccountCreation(true);
                      // setIsLoading(false);
                    } else {
                      dispatch(
                        setUserPersonalInfo({
                          updatePersonalInfoEnabled: false,
                          userPersonalInfo: {},
                          submittedInfo: { submit: true, success: false },
                        })
                      );
                      // setIsLoading(false);
                    }
                  })
                  .catch((err) => {
                    //console.log(err);
                    dispatch(
                      setUserPersonalInfo({
                        updatePersonalInfoEnabled: false,
                        userPersonalInfo: {},
                        submittedInfo: { submit: true, success: false },
                      })
                    );
                    // setIsLoading(false);
                  });
                //console.log(values);
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    {/* <p className="required-textcontent">{props.fields.RequiredField.value}</p> */}
                    <CardBlock>
                      <Text field={props.fields.UserInformationDesc} />
                      <div className="textinput-flex">
                        <div className="firstinput-wrapper">
                          <TextInput
                            type="text"
                            value={values.FirstName}
                            name="FirstName"
                            errors={errors}
                            touched={touched}
                            required={true}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            label={props.fields.FirstNameLabel}
                          />
                        </div>
                        <div className="secondinput-wrapper">
                          <TextInput
                            type="text"
                            value={values.LastName}
                            name="LastName"
                            errors={errors}
                            required={true}
                            touched={touched}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            label={props.fields.LastNameLabel}
                          />
                        </div>
                      </div>
                      <div className="content-divider"></div>
                      <div className="textinput-flex">
                        <div className="firstinput2-wrapper">
                          <TextInput
                            type="text"
                            value={values.StreetAdress}
                            name="StreetAdress"
                            required={true}
                            errors={errors}
                            touched={touched}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            label={props.fields.StreetAddressLabel}
                          />
                        </div>
                        <div className="secondinput2-wrapper">
                          <TextInput
                            type="text"
                            value={values.AptSuiteBuilding}
                            name="AptSuiteBuilding"
                            errors={errors}
                            touched={touched}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            label={props.fields.ApartmentLabel}
                          />
                        </div>
                      </div>
                      <div className="textinput-flex">
                        <div className="cityinput-wrapper">
                          <TextInput
                            type="text"
                            value={values.City}
                            name="City"
                            required={true}
                            errors={errors}
                            touched={touched}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            label={props.fields.CityLabel}
                          />
                        </div>
                        <div className="provinceinput-wrapper">
                          <div className="province-margin-perInfo">
                            <Select
                              name="Province"
                              errors={errors}
                              touched={touched}
                              options={options}
                              label={props.fields.ProvinceLabel.value}
                              labelA11y={props.fields.ProvinceLabelA11y.value}
                              defaultValue="ON"
                              defaultLabel="Ontario"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="textinput-flex">
                        <div className="countryinput-wrapper">
                          {/* <div className="province-margin">
                          <Select
                            fullWidth
                            name="Country"
                            options={Countries}
                            label={props.fields.Countrylabel.value}
                            labelA11y={props.fields.CountrylabelA11y.value}
                            defaultValue={Countries[0].value}
                            defaultLabel={Countries[0].label}
                          />
                        </div> */}
                          <TextInput
                            type="Country"
                            value="Canada"
                            name="Country"
                            errors={errors}
                            touched={touched}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            label={props.fields.CountryLabel}
                            disabled={true}
                          />
                        </div>
                        <div className="postalinput-wrapper ">
                          <TextInput
                            type="text"
                            value={values.PostalCode}
                            name="PostalCode"
                            errors={errors}
                            required={true}
                            touched={touched}
                            onChange={(e) => {
                              handleChange(e);
                              onPostalCodeChange(e);
                            }}
                            label={props.fields.PostalCodeLabel}
                          // bellowText={props.fields.Postalcodedesc}
                          />
                        </div>
                      </div>
                      <div className="textinput-flex">
                        <div className="countryinput-wrapper">
                          <TextInput
                            type="text"
                            value={values.PrimaryPhoneNo}
                            name="PrimaryPhoneNo"
                            errors={errors}
                            required={true}
                            touched={touched}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            label={props.fields.PrimaryPhoneNumber}
                          />
                        </div>
                        <div className="postalinput-wrapper">
                          <TextInput
                            type="text"
                            value={values.SecondaryPhoneNo}
                            name="SecondaryPhoneNo"
                            errors={errors}
                            required={true}
                            touched={touched}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            label={props.fields.SecondaryPhonenumber}
                          />
                        </div>
                      </div>
                      <div className="personal-info-divider"></div>
                      <div className="textinput-flex info-checkbox-padding">
                        <div>
                          <Checkbox
                            className="centeredCheckbox-label"
                            checked={contentchecked}
                            onChange={(e) => {
                              setContentChecked(!contentchecked);
                            }}
                            checkboxA11y={props.fields.PrestoContentCheckboxDesc.value}
                          >
                            <Text field={props.fields.PrestoContentCheckboxDesc} />
                          </Checkbox>
                        </div>
                      </div>
                      {props.fields.AdditionalCheckboxDesc.value === '' ? null :
                        <div className="textinput-flex info-checkbox-padding">
                          <div>
                            <Checkbox
                              className="centeredCheckbox-label"
                              checked={emailChecked}
                              onChange={(e) => {
                                onEmailCheckedChange(e);
                              }}
                              checkboxA11y={props.fields.AdditionalCheckboxDesc.value}
                            >
                              <Text field={props.fields.AdditionalCheckboxDesc} />
                            </Checkbox>
                          </div>
                        </div>
                      }
                      {/* <div className="create-note content-padding">
                        <Note>
                          <RichText field={props.fields.EndNote} />
                        </Note>
                      </div>
                      <div className="create-terms-block">
                        <Placeholder
                          name="presto-terms-and-conditions"
                          rendering={props.rendering}
                        />
                        {errors[sharedFormConstants.terms] &&
                          touched[sharedFormConstants.terms] && (
                            <InlineError cssClass="checkbox-error">
                              {errors[sharedFormConstants.terms]}
                            </InlineError>
                          )}
                      </div> */}
                    </CardBlock>
                    <div className="info-btn-flex">
                      <div className="info-btn-padding ">
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          buttonTextA11y={props.fields.CreateMyAccountTextA11y.value}
                        >
                          <Text field={props.fields.CreateMyAccountText} />
                        </Button>
                      </div>
                      {/* <div>
                  <Button
                    white
                    firstOrder
                    buttonTextA11y={props.fields.CancelTextA11y.value}
                    // onClick={() => discardChangesHandler()}
                  >
                    <Text field={props.fields.CancelText} />
                  </Button>
                </div> */}
                    </div>
                  </form>
                );
              }}
            </Formik>

            {
              <Modal
                title={props.fields.EmailOptOutTitle.value}
                text={props.fields.EmailOptOutDesc.value}
                textSubmit={props.fields.CloseText.value}
                useRevert
                disableBackdropClick
                disableEscapeKeyDown
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            }
          </div>
        ) : (
          accCreationSuccessScreen()
        )}
      </div>
    </div>
  );
};

export default B2CPersonalInformation;
