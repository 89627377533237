import api from '../../services/api';
import types from './types';
import { setTransitPasses, setTransitAgencyLogos, setDiscountTypes } from '../products/actions';
import { setSettings } from '../settings/actions';
import apiResponses from '../../constants/apiResponses';
import { setBusinessRules } from '../businessRules/actions';
import { redirectToUrlinCaseofFailure } from '../../services/utils';
import routes from '../../constants/routes';
import mediaTypes from '../../constants/mediaTypes';

export function setCheckoutTransitPage(payload) {
  return {
    type: types.SET_TRANSIT_CHECKOUT_PAGE,
    payload,
  };
}

export function updateLoginAndSecurityFields(payload) {
  return {
    type: types.UPDATE_LOGIN_AND_SECURITY_FIELDS,
    payload,
  };
}

export function setB2cClaimsResponse(payload) {
  return {
    type: types.SET_B2C_CLAIMS_RESPONSE,
    payload,
  };
}

export function setSelectedCardData(payload) {
  return {
    type: types.SET_SELECTED_CARD_DATA,
    payload,
  };
}

export function setBalanceAutoLoadData(payload) {
  return {
    type: types.SET_BALANCE_AUTOLOAD_DATA,
    payload,
  };
}

export function setShoppingCartShippingDetails(payload) {
  return {
    type: types.SET_SHOPPING_SHIPPING_DETAILS,
    payload,
  };
}

export function setNewCardDetails(payload) {
  return {
    type: types.SET_NEW_CARD_DETAILS,
    payload,
  };
}

export function setOrderCardResponse(payload) {
  return {
    type: types.SET_ORDER_CARD_RESPONSE,
    payload,
  };
}

export function setHotlistApiSuccess(payload) {
  return {
    type: types.SET_HOTLIST_API_SUCCESS,
    payload,
  };
}

export function setPaymentResponse(payload) {
  return {
    type: types.SET_PAYMENT_API_SUCCESS,
    payload,
  };
}

export function setDeactivateCardType(payload) {
  return {
    type: types.SET_DEACTIVATE_CARD_TYPE,
    payload,
  };
}

export function setShoppingCartId(payload) {
  return {
    type: types.SET_SHOPPING_CART_ID,
    payload,
  };
}
export function setUserSignedfromGuestFlow(payload) {
  return {
    type: types.SET_USER_SIGNED_FROM_GUEST_FLOW,
    payload,
  };
}

export function setUserSignedfromCreateAccount(payload) {
  return {
    type: types.SET_USER_SIGNED_FROM_CREATE_ACCOUNT,
    payload,
  };
}

export function setAddressType(payload) {
  return {
    type: types.SET_ADDRESS_TYPE,
    payload,
  };
}

export function setCurrentShippingDetails(payload) {
  return {
    type: types.SET_CURRENT_SHIPPING_DETAILS,
    payload,
  };
}

export function setNewShippingDetails(payload) {
  return {
    type: types.SET_NEW_SHIPPING_DETAILS,
    payload,
  };
}

export function fetchAllDataSuccessfullyLoaded(success) {
  return {
    type: types.FETCH_ALL_DATA,
    success,
  };
}

export function itemsHaveError(bool) {
  return {
    type: 'ITEMS_HAVE_ERROR',
    hasError: bool,
  };
}

export function changeUserStatus(authenticated, anonymous = false) {
  return {
    type: types.CHANGE_STATUS,
    payload: {
      authenticated,
      anonymous,
    },
  };
}
export function anonymousSaveAccessToken(payload) {
  return {
    type: types.ANONYMOUS_ACCESS_TOKEN,
    payload,
  };
}

export function updateCaslPopupStatus() {
  return {
    type: types.SET_CASL_POPUP_STATUS,
  };
}

export function updateCaslPopupOpenedStatus() {
  return {
    type: types.SET_IS_POPUP_OPENED,
  };
}

export function updateIsCardClickedStatus(payload) {
  return {
    type: types.SET_IS_CARD_CLICKED,
    payload,
  };
}

export function setUserInfo(userInfo) {
  return {
    type: types.SET_USER_INFO,
    payload: {
      ...userInfo,
    },
  };
}

export function setIsMobile(isMobile) {
  return {
    type: types.SET_IS_MOBILE,
    payload: isMobile,
  };
}

export function setUserPersonalInfo(data) {
  return {
    type: types.SET_USER_PERSONAL_INFO,
    payload: { ...data },
  };
}

export function setSessionInstanceId(sessionInstanceId) {
  return {
    type: types.SET_SESSION_INSTANCEID,
    payload: {
      sessionInstanceId,
    },
  };
}

export function addShoppingCartMessage(message) {
  return {
    type: types.ADD_SHOPPING_CART_MESSAGE,
    payload: {
      message,
    },
  };
}

export function setShoppingCart(data, isNewItem) {
  return {
    type: types.SET_SHOPPING_CART,
    payload: {
      ...data,
      isNewItem,
    },
  };
}

export function cardRemoved(isCardRemoved) {
  return {
    type: types.CARD_REMOVED,
    payload: {
      isCardRemoved,
    },
  };
}

export function deleteAccount(isAccountDeleted) {
  return {
    type: types.DELETE_ACCOUNT,
    payload: {
      isAccountDeleted,
    },
  };
}

export function removeNewItemFlag() {
  return {
    type: types.REMOVE_NEW_ITEM_FLAG,
    payload: {},
  };
}

export function itemsAreLoading(bool) {
  return {
    type: types.ITEMS_ARE_LOADING,
    payload: {
      isLoading: bool,
    },
  };
}

export function showScreenHeader(bool) {
  return {
    type: types.SHOW_SCREEN_HEADER,
    payload: {
      isScreenHeaderVisible: bool,
    },
  };
}

export function itemsFetchDataSuccess(items) {
  return {
    type: 'ITEMS_FETCH_DATA_SUCCESS',
    items,
  };
}

export function selectCard(
  card,
  config = { url: '', includeLanguage: true, isInternalLink: true, redirected: false }
) {
  return Promise.resolve({
    type: types.SELECT_CARD,
    payload: {
      selectedCard: card,
      redirectAfterCardSelected: config,
    },
  });
}

export function setSelectCardRedirect(
  redirectObj = { url: '', includeLanguage: true, isInternalLink: true, redirected: false }
) {
  return Promise.resolve({
    type: types.SELECT_CARD_REDIRECT,
    payload: {
      redirectAfterCardSelected: redirectObj,
    },
  });
}
export function setInitialCard(userinfo) {
  return {
    type: types.SET_INITIAL_CARD,
    payload: {
      ...userinfo,
    },
  };
}

export function weakPasswordAndQaA(bool) {
  return {
    type: types.WEAK_PASSWORD_AND_QAA,
    payload: {
      isWeakPasswordAndQaAVisible: bool,
    },
  };
}

export function weakPassword(bool) {
  return {
    type: types.WEAK_PASSWORD,
    payload: {
      isWeakPasswordVisible: bool,
    },
  };
}

export function weakQaA(bool) {
  return {
    type: types.WEAK_QAA,
    payload: {
      isWeakQaAVisible: bool,
    },
  };
}
export function setEmailError(emailError) {
  return {
    type: types.SET_EMAIL_ERROR,
    payload: {
      emailError,
    },
  };
}

export function setWeakAccount(isWeakAccount) {
  return {
    type: types.SET_WEAK_ACCOUNT,
    payload: {
      isWeakAccount,
    },
  };
}

export function setLoggedInTime(loggedInTime) {
  return {
    type: types.LOGGED_IN_TIME,
    payload: {
      loggedInTime,
    },
  };
}

export function setLastVisitedSecuredRoute(lastVisitedSecuredRoute) {
  return {
    type: types.LAST_VISITED_SECURED_ROUTE,
    payload: {
      lastVisitedSecuredRoute,
    },
  };
}
export function setFirstNamePersonalInfo(firstNameUpdatePersonalInfo) {
  return {
    type: types.FIRST_NAME_PERSONAL_INFO,
    payload: {
      firstNameUpdatePersonalInfo,
    },
  };
}

export function setLogOutTime(logOutTime) {
  return {
    type: types.LOGGED_OUT_TIME,
    payload: {
      logOutTime,
    },
  };
}

export function fetchUserInfo(dispatch, setResponseData, setCard = false) {
  return new Promise((resolve, reject) => {
    api
      .getUserInfo()
      .then((response) => {
        if (setResponseData) {
          setResponseData(response.data.Data);
        }
        if (response.data.Error) {
          const error = JSON.parse(response.data.Error);
          if (error.Code === apiResponses.unverifiedEmail) {
            dispatch(setEmailError(response.data.Error));
            // console.log('error1', response.data.Error);
            dispatch(fetchAllDataSuccessfullyLoaded(false));
            resolve();
          } else {
            dispatch(fetchAllDataSuccessfullyLoaded(false));
            redirectToUrlinCaseofFailure(routes.technicalDifficulties);
            reject();
          }
        } else if (response.status !== 200) {
          dispatch(fetchAllDataSuccessfullyLoaded(false));
          redirectToUrlinCaseofFailure(routes.technicalDifficulties);
          reject();
        } else if (response.data.Data.ReturnCode === apiResponses.weakPasswordAndQaA) {
          dispatch(setUserInfo(response.data.Data));
          if (setCard) {
            dispatch(setInitialCard(response.data.Data));
          }
          dispatch(weakPasswordAndQaA(true));
          dispatch(weakPassword(false));
          dispatch(weakQaA(false));
          resolve();
        } else if (response.data.Data.ReturnCode === apiResponses.weakPassword) {
          dispatch(setUserInfo(response.data.Data));
          if (setCard) {
            dispatch(setInitialCard(response.data.Data));
          }
          dispatch(weakPasswordAndQaA(false));
          dispatch(weakPassword(true));
          dispatch(weakQaA(false));
          resolve();
        } else if (response.data.Data.ReturnCode === apiResponses.weakQaA) {
          dispatch(setUserInfo(response.data.Data));
          if (setCard) {
            dispatch(setInitialCard(response.data.Data));
          }
          dispatch(weakPasswordAndQaA(false));
          dispatch(weakPassword(false));
          dispatch(weakQaA(true));
          resolve();
        } else {
          dispatch(setUserInfo(response.data.Data));
          if (setCard) {
            dispatch(setInitialCard(response.data.Data));
          }
          dispatch(weakPasswordAndQaA(false));
          dispatch(weakPassword(false));
          dispatch(weakQaA(false));
          resolve();
        }
      })
      .catch(() => {
        dispatch(fetchAllDataSuccessfullyLoaded(false));
        redirectToUrlinCaseofFailure(routes.technicalDifficulties);
        reject();
      });
  });
}

export function fetchAllCardsBalance(dispatch, getState) {
  const { user } = getState();
  let allcards = user.fareMedias.concat(user.contactlessMedias);
  allcards.forEach((card) => {
    if (card.currentBalance === null) {
      dispatch(getCurrentBalance(card.visibleId));
    }
  });
}

export function fetchServiceProviders(dispatch, getState) {
  const { user } = getState();

  return new Promise((resolve, reject) => {
    const { selectedCard } = user;

    const cardType = selectedCard?.type;
    if (cardType !== 'presto') {
      resolve();
      return;
    }

    const visibleId = selectedCard?.visibleId;
    if (!visibleId) {
      resolve();
      return;
    }

    api
      .getServiceProviders({
        visibleId,
      })
      .then(({ data }) => {
        if (!data.Success) {
          reject();
        } else {
          dispatch(setTransitPasses(data));
          resolve();
        }
      })
      .catch(() => {
        reject();
      });
  });
}

export function fetchTransitAgencyLogos(dispatch) {
  return new Promise((resolve, reject) => {
    api
      .getTransitAgencyLogos()
      .then(({ data }) => {
        dispatch(setTransitAgencyLogos(data));
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
}

export function fetchDiscountTypes(dispatch) {
  return new Promise((resolve, reject) => {
    api
      .getDiscountTypes()
      .then(({ data }) => {
        dispatch(setDiscountTypes(data));
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
}
export function fetchWalletCardDetails(dispatch, getState) {
  const { user } = getState();
  let allGWCards = [];
  const googleWallets = user?.contactlessMedias?.filter(
    (card) => card.mediaType === mediaTypes.CRDNC
  );
  googleWallets.forEach((card, index) => {
    allGWCards.push({ DPanId: card.dpan });
    if (index === googleWallets.length - 1) {
      api.getAllWalletCardDetails([allGWCards[0]]).then((res) => {
        if (res?.data?.status === 'ACCEPTED') {
          res.data.mediaList.map((card) => {
            const data = {
              mediaList: [card],
              displayText: res?.data?.displayText,
            };
            dispatch(setGoogleWalletData(data));
            const latestVersion = Math.max(
              ...Object.values(data.mediaList[0].mediaInfo.provisionedMedias)?.map((media) =>
                parseInt(media.versionNbr)
              )
            );

            const latestItem = Object.values(data.mediaList[0].mediaInfo.provisionedMedias)?.find(
              (media) => media.versionNbr === latestVersion.toString()
            );
            let mediaSubType = null;
            if (latestItem && Object.keys(latestItem).length > 0) {
              mediaSubType = latestItem.mediaSubType;
            } else {
              const inactiveMedia = Object.values(
                data.mediaList[0].mediaInfo.provisionedMedias
              ).filter((media) => media.status !== 'ACTIVE' && media.status !== 'HOTLISTED');
              const filteredMedia = inactiveMedia.filter(
                (media) =>
                  media.mediaSubType === 'CRDNC_GOOG' || media.mediaSubType === 'CRDNC_AAPL'
              );
              filteredMedia.sort((a, b) => b.versionNbr - a.versionNbr);

              if (filteredMedia.length > 0) {
                mediaSubType = filteredMedia[0].mediaSubType;
              }
            }
            api
              .getStoredMediaTypeInSession({
                MediaType: mediaSubType,
              })
              .then((res) => {
                console.log('response', res);
              });
          });
        }
      });
    }
  });
}
export function fetchWalletCardDetailsSigninSuccess(dispatch, getState) {
  return new Promise((resolve, reject) => {
    const { user } = getState();
    let allGWCards = [];
    let mediaSubType = null;
    const googleWallets = user?.contactlessMedias?.filter(
      (card) => card.mediaType === mediaTypes.CRDNC
    );
    if (googleWallets?.length) {
      googleWallets.forEach((card, index) => {
        allGWCards.push({ DPanId: card.dpan });
        if (index === googleWallets.length - 1) {
          api
            .getAllWalletCardDetails([allGWCards[0]])
            .then((res) => {
              if (res?.data?.status === 'ACCEPTED') {
                res.data.mediaList.map((card) => {
                  const data = {
                    mediaList: [card],
                    displayText: res?.data?.displayText,
                  };
                  dispatch(setGoogleWalletData(data));
                  const latestVersion = Math.max(
                    ...Object.values(data.mediaList[0].mediaInfo.provisionedMedias)?.map((media) =>
                      parseInt(media.versionNbr)
                    )
                  );

                  const latestItem = Object.values(
                    data.mediaList[0].mediaInfo.provisionedMedias
                  )?.find((media) => media.versionNbr === latestVersion.toString());

                  if (latestItem && Object.keys(latestItem).length > 0) {
                    mediaSubType = latestItem.mediaSubType;
                  } else {
                    const inactiveMedia = Object.values(
                      data.mediaList[0].mediaInfo.provisionedMedias
                    ).filter((media) => media.status !== 'ACTIVE' && media.status !== 'HOTLISTED');
                    const filteredMedia = inactiveMedia.filter(
                      (media) =>
                        media.mediaSubType === 'CRDNC_GOOG' || media.mediaSubType === 'CRDNC_AAPL'
                    );
                    filteredMedia.sort((a, b) => b.versionNbr - a.versionNbr);

                    if (filteredMedia.length > 0) {
                      mediaSubType = filteredMedia[0].mediaSubType;
                    }
                  }


                });
                resolve(mediaSubType);
              }
            })
            .catch(() => {
              reject();
            });
        }
      });
    }
  });
}

export function fetchWalletDetails(dispatch, getState) {
  return new Promise((resolve, reject) => {
    const { user } = getState();
    let allGWCards = [];
    const googleWallets = user?.contactlessMedias?.filter(
      (card) => card.mediaType === mediaTypes.CRDNC
    );
    googleWallets.forEach((card, index) => {
      allGWCards.push({ DPanId: card.dpan });
      if (index === googleWallets.length - 1) {
        api
          .getAllWalletCardDetails(allGWCards)
          .then((res) => {
            if (res?.data?.status === 'ACCEPTED') {
              res.data.mediaList.map((card) => {
                const data = {
                  mediaList: [card],
                  displayText: res?.data?.displayText,
                };
                dispatch(setGoogleWalletData(data));
                resolve();
              });
            }
          })
          .catch(() => {
            reject();
          });
      }
    });
  });
}

export function fetchAvailableSettings(dispatch) {
  return new Promise((resolve, reject) => {
    api
      .getAvailableSettings()
      .then(({ data }) => {
        if (!data.Success) {
          reject();
        } else {
          dispatch(setSettings(data));
          resolve();
        }
      })
      .catch(() => {
        reject();
      });
  });
}

export function fetchStoreMediaSession(mediaSubType) {
  return new Promise((resolve, reject) => {
    api
      .getStoredMediaTypeInSession({
        MediaType: mediaSubType,
      })
      .then((res) => {
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
}

export function fetchAvailableBusinessRules(dispatch) {
  return new Promise((resolve, reject) => {
    api
      .getAllBusinessRules()
      .then(({ data }) => {
        dispatch(setBusinessRules(data));
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
}

export function fetchSitecoreSessionId(dispatch) {
  return new Promise((resolve, reject) => {
    api
      .getSitecoreSessionId()
      .then(({ data }) => {
        if (!data.Success) {
          reject();
        } else {
          dispatch(setSessionInstanceId(data.Data));
          resolve();
        }
      })
      .catch(() => {
        reject();
      });
  });
}

export function fetchWebChatCookie(getState) {
  const { user } = getState();
  return new Promise((resolve, reject) => {
    api
      .setWebChatCookie({ customerId: user.customerId })
      .then((response) => {
        if (response.status === 200) {
          resolve();
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      });
  });
}

function resetShoppingCart(dispatch) {
  dispatch(setShoppingCart({ OrderLines: [], TotalAmount: 0 }, false));
}

export function fetchShoppingCart(dispatch, getState) {
  resetShoppingCart(dispatch);
  const { user } = getState();

  return new Promise((resolve, reject) => {
    const { customerId, selectedCard } = user;
    if (!customerId || !selectedCard) {
      reject();
      return;
    }

    const cardType = selectedCard?.type;
    if (cardType !== 'presto') {
      resolve();
      return;
    }

    if (!selectedCard.visibleId) {
      resolve();
      return;
    }

    api
      .getShoppingCart({
        customerId,
        visibleId: selectedCard.visibleId,
        mediaId: selectedCard.mediaId,
      })
      .then((response) => {
        if (response.status !== 200) {
          reject();
        } else if (response.data.Error) {
          const error = JSON.parse(response.data.Error);
          if (error.Code === apiResponses.shoppingCartNotExists) {
            resolve();
          } else {
            reject();
          }
        } else if (!response.data.ShoppingCart) {
          reject();
        } else {
          const order = response.data.ShoppingCart.Order;
          const isNewItem = false;
          dispatch(setShoppingCart(order, isNewItem));
          resolve();
        }
      })
      .catch(() => {
        reject();
      });
  });
}

export function fetchUserInfoData() {
  return (dispatch, getState) => {
    fetchUserInfo(dispatch)
      .then(() => {
        const { user } = getState();
        let allcards = user.fareMedias.concat(user.contactlessMedias);
        allcards.forEach((card) => {
          if (card.currentBalance === null) {
            dispatch(getCurrentBalance(card.visibleId));
          }
        });
      })
      .catch((error) => { });
  };
}

function hasCRDNC(mediaInstances) {
  return mediaInstances?.some((media) => media.mediaType === mediaTypes.CRDNC);
}

export function fetchAllData(setInitialCard = true) {
  return (dispatch, getState) => {
    let mediaSubType = null;
    let userResponse = null;

    fetchUserInfo(
      dispatch,
      (res) => {
        userResponse = res;
      },
      setInitialCard
    )
      .then(() => {
        if (
          userResponse?.Account?.mediaInstances?.length > 0 &&
          hasCRDNC(userResponse?.Account?.mediaInstances) &&
          userResponse?.Role === 'Registered'
        ) {
          return fetchWalletCardDetailsSigninSuccess(dispatch, getState);
        }
      })
      .then((mediaType) => {
        if (
          userResponse?.Account?.mediaInstances?.length > 0 &&
          hasCRDNC(userResponse?.Account?.mediaInstances) &&
          userResponse?.Role === 'Registered'
        ) {
          mediaSubType = mediaType;
          return fetchStoreMediaSession(mediaSubType);
        }
      })
      .then(() => {
        if (
          userResponse?.Account?.mediaInstances?.length > 0 &&
          hasCRDNC(userResponse?.Account?.mediaInstances) &&
          userResponse?.Role === 'Registered'
        ) {
          return fetchWalletDetails(dispatch, getState);
        }
      })
      .then(() => {
        Promise.all([
          fetchWebChatCookie(getState),
          fetchAllCardsBalance(dispatch, getState),
          fetchServiceProviders(dispatch, getState),
          fetchTransitAgencyLogos(dispatch),
          fetchAvailableBusinessRules(dispatch),
          fetchSitecoreSessionId(dispatch),
          fetchShoppingCart(dispatch, getState),
          fetchDiscountTypes(dispatch),
        ])
          .then(() => {
            dispatch(fetchAllDataSuccessfullyLoaded(true));
          })
          .catch((error) => {
            dispatch(itemsHaveError(true));
            // console.log('error2', error);
            dispatch(fetchAllDataSuccessfullyLoaded(false));
          });
      })
      .catch((error) => {
        dispatch(itemsHaveError(true));
        // console.log('error3', error);
        dispatch(fetchAllDataSuccessfullyLoaded(false));
      });
  };
}

export function updateUserInfo(dispatch, setCard = false) {
  return (dispatch, getState) => {
    let userResponse = null;
    fetchUserInfo(
      dispatch,
      (res) => {
        userResponse = res;
      },
      setCard
    ).then(() => {
      if (
        userResponse?.Account?.mediaInstances?.length > 0 &&
        hasCRDNC(userResponse?.Account?.mediaInstances) &&
        userResponse?.Role === 'Registered'
      ) {
        return fetchWalletDetails(dispatch, getState);
      }
    });
  };
}

export function refreshUserDataAfterPrestoCardAdded(setCard = false) {
  return (dispatch, getState) => {
    let mediaSubType = null;
    let userResponse = null;

    fetchUserInfo(
      dispatch,
      (res) => {
        userResponse = res;
      },
      setCard
    )
      .then(() => {
        if (
          userResponse?.Account?.mediaInstances?.length > 0 &&
          hasCRDNC(userResponse?.Account?.mediaInstances) &&
          userResponse?.Role === 'Registered'
        ) {
          return fetchWalletCardDetailsSigninSuccess(dispatch, getState);
        }
      })
      .then((mediaType) => {
        if (
          userResponse?.Account?.mediaInstances?.length > 0 &&
          hasCRDNC(userResponse?.Account?.mediaInstances) &&
          userResponse?.Role === 'Registered'
        ) {
          mediaSubType = mediaType;
          return fetchStoreMediaSession(mediaSubType);
        }
      })
      .then(() => {
        Promise.all([
          fetchServiceProviders(dispatch, getState),
          fetchTransitAgencyLogos(dispatch),
          fetchShoppingCart(dispatch, getState),
        ])
          .then(() => {
            dispatch(fetchAllDataSuccessfullyLoaded(true));
          })
          .catch((error) => {
            dispatch(itemsHaveError(true));
            // console.log('error5', error);
            dispatch(fetchAllDataSuccessfullyLoaded(false));
          });
      })
      .catch((error) => {
        dispatch(itemsHaveError(true));
        // console.log('error4', error);
        dispatch(fetchAllDataSuccessfullyLoaded(false));
      });
  };
}

export function removeAllFromShoppingCart() {
  return {
    type: types.REMOVE_ALL_FROM_SHOPPING_CART,
    payload: {
      shoppingCartQuantity: 0,
      shoppingCart: {
        addMessage: [],
        isNewItem: false,
        items: [],
      },
    },
  };
}

export function emptyShoppingCartMessage() {
  return {
    type: types.EMPTY_SHOPPING_CART_MESSAGE,
  };
}

export function removeFromShoppingCart(data, callback = null) {
  return (dispatch) => {
    api.removeProductFromCart(data).then((response) => {
      if (callback) {
        callback();
      }
      if (response.status !== 200) {
        throw Error(response.statusText);
      }
      if (response.data.Success) {
        dispatch(setShoppingCart(response?.data?.ShoppingCart.Order, false));
        // dispatch(cardRemoved(true));
      } else {
        let errorObj = JSON.parse(response.data.Error);
        if (errorObj.Code) {
          throw Error(errorObj.Description);
        }
        throw Error('Error');
      }
    });
  };
}

export function itemsFetchData(reqMethod, successAction) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true));

    api[reqMethod]
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.statusText);
        }

        dispatch(itemsAreLoading(false));

        return response;
      })
      .then((response) => dispatch(successAction(response.data)))
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export function setMediaCurrentBalance(data) {
  return {
    type: types.SET_MEDIA_CURRENT_BALANCE,
    payload: {
      visibleId: data.visibleId,
      currentBalance: data.currentBalance,
    },
  };
}

export function setMediaCurrentPasses(data) {
  return {
    type: types.SET_MEDIA_CURRENT_PASSES,
    payload: {
      visibleId: data.visibleId,
      passes: data.passes,
    },
  };
}
export function getUpdatedGoogleWalletData(data) {
  const getFareTypeData = () => {
    const concessionArray = Object.keys(data.mediaList[0].mediaProfile.concessions);
    const isOtherServiceProviderIdExists = concessionArray?.some((id) => id != 0);
    const fareTypeId = data.mediaList[0].mediaProfile.concessions[0].id;
    const isAdultSeniorFaretype = fareTypeId === 0 || fareTypeId === 2;
    const expiryDate = !isAdultSeniorFaretype
      ? data.mediaList[0].mediaProfile.concessions[0].expirationDate
      : 'N/A';
    const type = `CONCESSION0_${fareTypeId}`;
    const fareType = data.displayText[type];
    return {
      expiryDate,
      fareType,
      isOtherServiceProviderIdExists,
    };
  };
  const { expiryDate, fareType, isOtherServiceProviderIdExists } = getFareTypeData();
  const autoload = data.mediaList[0].products.subscriptions?.some(
    (sub) => sub.subscriptionType === 'FUNDS' && sub.fundSubscription.status === 'ACTIVE'
  );
  const latestVersion = Math.max(
    ...Object.values(data.mediaList[0].mediaInfo.provisionedMedias)?.map((media) =>
      parseInt(media.versionNbr)
    )
  );

  const latestItem = Object.values(data.mediaList[0].mediaInfo.provisionedMedias)?.find(
    (media) => media.versionNbr === latestVersion.toString()
  );

  const hasActiveImageGoog =
    latestItem &&
    (latestItem.status === 'HOTLISTED' || latestItem.status === 'ACTIVE') &&
    latestItem.mediaSubType === 'CRDNC_GOOG';

  const hasActiveImageAppl =
    latestItem &&
    (latestItem.status === 'HOTLISTED' || latestItem.status === 'ACTIVE') &&
    latestItem.mediaSubType === 'CRDNC_AAPL';

  let mediaSubTypeWallet = null;
  if (latestItem && Object.keys(latestItem).length > 0) {
    mediaSubTypeWallet = latestItem.mediaSubType;
  } else {
    const inactiveMedia = Object.values(data.mediaList[0].mediaInfo.provisionedMedias).filter(
      (media) => media.status !== 'ACTIVE' && media.status !== 'HOTLISTED'
    );
    const filteredMedia = inactiveMedia.filter(
      (media) => media.mediaSubType === 'CRDNC_GOOG' || media.mediaSubType === 'CRDNC_AAPL'
    );

    filteredMedia.sort((a, b) => b.versionNbr - a.versionNbr);
    if (filteredMedia.length > 0) {
      mediaSubTypeWallet = filteredMedia[0].mediaSubType;
    }
  }
  const allActivePasses = Object.keys(data.mediaList[0].products.commutePlans);
  const activePasses = allActivePasses.filter(
    (id) => data.mediaList[0].products?.commutePlans[id]?.length > 0
  );
  const extractDefaultTrips = () => {
    const defaultTrips = data.mediaList[0].mediaProfile.defaultTrips;
    const displayText = data.displayText;
    if (Object.keys(defaultTrips).length === 0) {
      return { showDefaultTrips: false };
    }
    const fromStopKey = `STOPS1_${defaultTrips['1'].fromLocationId}`;
    const toStopKey = `STOPS1_${defaultTrips['1'].toLocationId}`;
    const fromLocationId = displayText[fromStopKey];
    const toLocationId = displayText[toStopKey];

    return { showDefaultTrips: true, fromLocationId, toLocationId };
  };
  return {
    dpan: data.mediaList[0].mediaInfo.dpan,
    cardNumber: data.mediaList[0].mediaInfo.fpan,
    currentBalance: data.mediaList[0].products.funds,
    status: data.mediaList[0].mediaInfo.provisionedMedias.status,
    passes: data.mediaList[0].products.commutePlans,
    subscriptions: data.mediaList[0].products.subscription,
    defaultTrips: data.mediaList[0].mediaProfile.defaultTrips,
    concession: data.mediaList[0].mediaProfile.concessions,
    displayText: data.displayText,
    expiryDate,
    fareType,
    isOtherServiceProviderIdExists,
    autoload,
    hasActiveImageGoog,
    hasActiveImageAppl,
    mediaSubTypeWallet,
    activePasses,
    defaultTripsData: extractDefaultTrips(),
  };
}
export function setGoogleWalletData(data) {
  const updatedGWData = getUpdatedGoogleWalletData(data);
  return {
    type: types.SET_GOOGLE_WALLET_DATA,
    payload: updatedGWData,
  };
}
export function setMediaNickName(data) {
  return {
    type: types.SET_MEDIA_NICK_NAME,
    payload: {
      NickName: data.NickName,
      IsPrimary: data.IsPrimary,
      visibleId: data.visibleId,
    },
  };
}

export function setContactlessMediaNickName(data) {
  return {
    type: types.SET_CONTACTLESS_MEDIA_NICK_NAME,
    payload: {
      NickName: data.NickName,
      visibleId: data.visibleId,
    },
  };
}

export function getCurrentBalance(visibleId) {
  return (dispatch, getState) => {
    const { user } = getState();
    let allcards = user?.fareMedias?.concat(user.contactlessMedias);
    let thisCard = allcards?.find((card) => card.visibleId === visibleId);
    let cardDetails = thisCard?.products?.find((el) => el.ProductId === null);
    dispatch(setMediaCurrentBalance({ visibleId, currentBalance: cardDetails?.Balance }));
  };
}

export function getCurrentPasses(visibleId) {
  return (dispatch) => {
    api
      .getAllProductsLoadedInMedia({ visibleId })
      .then((response) => {
        if (response.data.Success && response.data.productInstances) {
          dispatch(setMediaCurrentPasses({ visibleId, passes: response.data.productInstances }));
        }
      })
      .catch(() => { });
  };
}

export function setNotification(notification) {
  return {
    type: types.SET_NOTIFICATION,
    payload: {
      notification,
    },
  };
}
export function updateNotification(key, value, updatedNotificationObj) {
  return {
    type: types.UPDATE_NOTIFICATION,
    payload: {
      key,
      value,
      updatedNotificationObj,
    },
  };
}

export function dismissNotification(index) {
  return {
    type: types.DISMISS_NOTIFICATION,
    payload: {
      index,
    },
  };
}

export function setPendingProducts({ list, orders }) {
  return {
    type: types.SET_PENDING_PRODUCTS,
    payload: {
      pendingProducts: {
        list,
        orders,
      },
    },
  };
}

export function fetchPendingProducts(visibleId) {
  return (dispatch) => {
    api
      .getPendingProductsLoadedInMedia({ VisibleId: visibleId })
      .then((res) => {
        if (res.data.Success) {
          dispatch(
            setPendingProducts({
              list: res.data?.ProductInstances || [],
              orders: res.data?.OrderLines || [],
            })
          );
        } else {
          dispatch(setPendingProducts([]));
        }
      })
      .catch(() => {
        dispatch(setPendingProducts([]));
      });
  };
}

export function setGuestUserEnabled(data) {
  return {
    type: types.SET_GUEST_USER_ENABLED,
    payload: { ...data },
  };
}
export function setGuestSignPopupEnabled(data) {
  return {
    type: types.SET_GUEST_SIGN_POPUP_ENABLED,
    payload: { ...data },
  };
}

export function setGuestSignPopupEnabledfromOrdercard(payload) {
  return {
    type: types.SET_GUEST_SIGN_POPUP_ENABLED_FROM_ORDERCARD,
    payload,
  };
}

export function setGuestUserCartCheckout(data) {
  return {
    type: types.SET_GUEST_USER_CHECKOUT,
    payload: { ...data },
  };
}

export function setGuestShoppingCartToken(payload) {
  return {
    type: types.SET_GUEST_SHOPPING_CART_TOKEN,
    payload,
  };
}

export function setGuestShoppingCartPayload(payload) {
  return {
    type: types.SET_GUEST_SHOPPING_CART_PAYLOAD,
    payload,
  };
}

export function setGoBackURL(payload) {
  return {
    type: types.GO_BACK_URL,
    payload,
  };
}
export function setTtcCheckoutValues(payload) {
  return {
    type: types.TTC_CHECKOUT_VALUES,
    payload,
  };
}
