export default {
    FETCH_ALL_DATA: 'user/FETCH_ALL_DATA',
    CHANGE_STATUS: 'user/CHANGE_STATUS',
    SET_CASL_POPUP_STATUS: 'user/SET_CASL_POPUP_STATUS',
    SET_IS_POPUP_OPENED: 'user/SET_IS_POPUP_OPENED',
    SET_USER_INFO: 'user/SET_USER_INFO',
    SET_SESSION_INSTANCEID: 'user/SET_SESSION_INSTANCEID',
    SET_SHOPPING_CART: 'user/SET_SHOPPING_CART',
    ADD_SHOPPING_CART_MESSAGE: 'user/ADD_SHOPPING_CARD_MESSAGE',
    REMOVE_NEW_ITEM_FLAG: 'user/REMOVE_NEW_ITEM_FLAG',
    ITEMS_ARE_LOADING: 'user/ITEMS_ARE_LOADING',
    SHOW_SCREEN_HEADER: 'user/SHOW_SCREEN_HEADER',
    WEAK_PASSWORD_AND_QAA: 'user/WEAK_PASSWORD',
    WEAK_PASSWORD: 'user/WEAK_PASSWORD',
    WEAK_QAA: 'user/WEAK_QAA',
    SELECT_CARD: 'user/SELECT_CARD',
    SET_MEDIA_CURRENT_BALANCE: 'user/SET_MEDIA_CURRENT_BALANCE',
    REMOVE_ALL_FROM_SHOPPING_CART: 'user/REMOVE_ALL_FROM_SHOPPING_CART',
    SET_NOTIFICATION: 'user/SET_NOTIFICATION',
    UPDATE_NOTIFICATION: 'user/UPDATE_NOTIFICATION',
    DISMISS_NOTIFICATION: 'user/DISMISS_NOTIFICATION',
    SET_PENDING_PRODUCTS: 'user/SET_PENDING_PRODUCTS',
    SET_EMAIL_ERROR: 'user/SET_EMAIL_ERROR',
    SET_WEAK_ACCOUNT: 'user/SET_WEAK_ACCOUNT',
    SET_NEW_SHIPPING_DETAILS: 'user/SET_NEW_SHIPPING_DETAILS',
    SET_CURRENT_SHIPPING_DETAILS: 'user/SET_CURRENT_SHIPPING_DETAILS',
    SET_ADDRESS_TYPE: 'user/SET_ADDRESS_TYPE',
    SET_SHOPPING_CART_ID: 'user/SET_SHOPPING_CART_ID',
    SET_DEACTIVATE_CARD_TYPE: 'user/SET_DEACTIVATE_CARD_TYPE',
    SET_HOTLIST_API_SUCCESS: 'user/SET_HOTLIST_API_SUCCESS',
    SET_PAYMENT_API_SUCCESS: 'user/SET_PAYMENT_API_SUCCESS',
    SET_ORDER_CARD_RESPONSE: 'user/SET_ORDER_CARD_RESPONSE',
    SET_NEW_CARD_DETAILS: 'user/SET_NEW_CARD_DETAILS',
    SET_SHOPPING_SHIPPING_DETAILS: 'user/SET_SHOPPING_SHIPPING_DETAILS',
    SET_BALANCE_AUTOLOAD_DATA: 'user/SET_BALANCE_AUTOLOAD_DATA',
    SET_SELECTED_CARD_DATA: 'user/SET_SELECTED_CARD_DATA',
    SET_USER_PERSONAL_INFO: 'user/SET_USER_PERSONAL_INFO',
    SET_TRANSIT_CHECKOUT_PAGE: 'user/SET_TRANSIT_CHECKOUT_PAGE',
    EMPTY_SHOPPING_CART_MESSAGE: 'user/EMPTY_SHOPPING_CART_MESSAGE',
    SET_GUEST_USER_ENABLED: 'user/SET_GUEST_USER_ENABLED',
    SET_GUEST_SIGN_POPUP_ENABLED: 'SET_GUEST_SIGN_POPUP_ENABLED',
    SET_GUEST_USER_CHECKOUT: 'SET_GUEST_USER_CHECKOUT',
    SET_USER_SIGNED_FROM_GUEST_FLOW: 'SET_USER_SIGNED_FROM_GUEST_FLOW',
    SET_GUEST_SHOPPING_CART_TOKEN: 'SET_GUEST_SHOPPING_CART_TOKEN',
    SET_GUEST_SHOPPING_CART_PAYLOAD: 'SET_GUEST_SHOPPING_CART_PAYLOAD',
    SET_USER_SIGNED_FROM_CREATE_ACCOUNT: 'SET_USER_SIGNED_FROM_CREATE_ACCOUNT',
    ANONYMOUS_ACCESS_TOKEN: 'user/ANONYMOUS_ACCESS_TOKEN',
    SET_GUEST_SIGN_POPUP_ENABLED_FROM_ORDERCARD: 'SET_GUEST_SIGN_POPUP_ENABLED_FROM_ORDERCARD',
    CARD_REMOVED: 'CARD_REMOVED',
    GO_BACK_URL: 'GO_BACK_URL',
    SET_MEDIA_NICK_NAME: 'SET_MEDIA_NICK_NAME',
    SET_CONTACTLESS_MEDIA_NICK_NAME: 'SET_CONTACTLESS_MEDIA_NICK_NAME',
    SET_INITIAL_CARD: 'SET_INITIAL_CARD',
    SET_MEDIA_CURRENT_PASSES: 'SET_MEDIA_CURRENT_PASSES',
    TTC_CHECKOUT_VALUES: 'TTC_CHECKOUT_VALUES',
    SELECT_CARD_REDIRECT: 'user/SELECT_CARD_REDIRECT',
    LOGGED_IN_TIME: 'user/LOGGED_IN_TIME',
    LOGGED_OUT_TIME: 'user/LOGGED_OUT_TIME',
    LAST_VISITED_SECURED_ROUTE: 'user/LAST_VISITED_SECURED_ROUTE',
    FIRST_NAME_PERSONAL_INFO: 'user/FIRST_NAME_PERSONAL_INFO',
    SET_GOOGLE_WALLET_DATA: 'user/SET_GOOGLE_WALLET_DATA',
    SET_IS_CARD_CLICKED: 'user/SET_IS_CARD_CLICKED',
    SET_IS_MOBILE: 'user/SET_IS_MOBILE',
    DELETE_ACCOUNT: 'user/DELETE_ACCOUNT',
    UPDATE_LOGIN_AND_SECURITY_FIELDS: 'user/UPDATE_LOGIN_AND_SECURITY_FIELDS',
    SET_B2C_CLAIMS_RESPONSE: 'user/SET_B2C_CLAIMS_RESPONSE',
};
