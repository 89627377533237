import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { showScreenHeader } from '../../redux/user/actions';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import api from '../../services/api';
import {
  getServerUrl,
  getLongLanguageCode,
  formatDecimal,
  isJsonString,
} from '../../services/utils';
import sharedFormConstants from '../../constants/sharedFormConstants';
import { RadioInput, ButtonBlock, Button, Loader } from '../../atoms';
import { Error, PaymentModal } from '../../molecules';
import CreditCardImg from '../../assets/images/credit_card.png';
import AmericanExpress from '../../assets/images/Latest_American_Express.png';
import Interac from '../../assets/images/card_interac.png';
import MasterCard from '../../assets/images/mastercard_new.png';
import VisaCardImg from '../../assets/images/Visa_latest.png';
import Sears from '../../assets/images/Sears.png';
import MonerisVault from '../../assets/images/MonerisVault.png';
import MonerisToken from '../../assets/images/MonerisToken.png';
import Master from '../../assets/images/Master.png';
import Jcb from '../../assets/images/Jcb.png';
import Discover from '../../assets/images/Discover.png';
import Debit from '../../assets/images/Debit.png';
import Unipay from '../../assets/images/Unipay.png';

export default function SetAutoload2({
  fields,
  toNextStep,
  setState,
  state,
  toPreviousStep,
  userInfo,
  configKeys,
  t,
  returnUrl,
  isError,
  errorContent,
  rendering,
}) {
  const [error, setIsError] = useState('');
  const dispatch = useDispatch();
  const [isSavedPaymentSetForCustomer, setSavedPaymentSetForCustomer] = useState(false);
  const [isSavedPaymentSetForCustomerSelected, setSavedPaymentSetForCustomerSelected] = useState(
    false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isGetTicketFetching, setIsGetTicketFetching] = useState(false);
  const [showCard, setShowCard] = useState({ paymenMethodsList: [] });
  const [isGetTicketFetchError, setIsGetTicketFetchError] = useState('');
  const [autoloadSubscriptionError, setAutoloadSubscriptionError] = useState('');

  const customerId = userInfo.customerId;
  const visibleId = userInfo.selectedCard.visibleId;
  const userName = userInfo.userName;

  const radioStyle = {
    margin: '16px 0px 16px 0px',
  };
  const errorStyle = {
    margin: '15px 0px',
  };

  useEffect(() => {
    setIsLoading(true);
    api
      .getRegisterPaymentMeansForCustomer({
        CustomerId: customerId,
        VisibleId: visibleId,
      })
      .then((response) => {
        if (response.data?.Success) {
          if (response.data.RegisterPaymentMeansList[0]?.RegFormOfPaymRecId) {
            setSavedPaymentSetForCustomer(true);
            setShowCard({
              paymenMethodsList: response.data.RegisterPaymentMeansList,
            });
            setIsLoading(false);
            //To pre-select radio button on pageload

            var id = fields.creditCardOption.value;
            document.getElementById(id).checked = true;
            setState({
              ...state,
              selectedCard: sharedFormConstants.cardOptionDebit,
            });

            /*
            var id = `**** ${response.data.RegisterPaymentMeansList[0].CreditCard.TruncatedPAN.slice(
              -4
            )}`;


            if (document.getElementById(id) != null) {
              document.getElementById(id).checked = true;
            }
            setState({
              ...state,
              selectedCard: sharedFormConstants.cardOptionSaved,
              regFormOfPaymRecId: response.data.RegisterPaymentMeansList[0].RegFormOfPaymRecId,
            });
             */
          } else {
            setIsLoading(false);
            var id = fields.creditCardOption.value;
            document.getElementById(id).checked = true;
            setState({
              ...state,
              selectedCard: sharedFormConstants.cardOptionDebit,
            });
          }
        } else {
          setIsLoading(false);
        }
      });

    api
      .getAccessToken()
      .then((accessToken) => setToken(accessToken))
      .catch(() => {
        setToken('');
      });
  }, []);

  const monerisEnvironment =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.Environment').map((config) => config.setting);

  const postCpgAdHocPaymentForm = async () => {
    document.getElementById('frmPayment').submit();
  };

  const handleMoneris = () => {
    setIsOpen(true);
    setIsLoading(false);
    setIsGetTicketFetching(true);
    api
      .getTicket({
        paymentProfileType: 'TOKENIZE',
        OrderNumber: '',
        customerId: customerId,
        VisibleId: visibleId,
        userName: userName,
        amount: 0,
      })
      .then((response) => {
        if (response.data.Success === true) {
          var myCheckout = new window.monerisCheckout();
          myCheckout.setMode(monerisEnvironment[0]);
          myCheckout.setCheckoutDiv('monerisCheckout');
          myCheckout.setCallback('page_loaded', PageLoad);
          myCheckout.setCallback('cancel_transaction', CancelTransaction);
          myCheckout.setCallback('error_event', ErrorEvent);
          myCheckout.setCallback('payment_receipt', PaymentReceipt);
          myCheckout.setCallback('payment_complete', PaymentComplete);
          myCheckout.startCheckout(response.data.TicketResponse.ticket);
          const ticketResponse = response.data.TicketResponse.ticket;

          function PageLoad() {
            setIsGetTicketFetching(false);
          }

          function CancelTransaction() {
            setIsOpen(false);
          }

          function PaymentComplete(args) {
            document.getElementById('monerisCheckout').innerHTML = '';
            document.getElementById('monerisCheckout').style = '';
            const parsedData = isJsonString(args) ? JSON.parse(args) : '';
            if (parsedData?.response_code !== '001') {
              setIsOpen(false);
              setAutoloadSubscriptionError(t('monerisPaymentError'));
            } else {
              setIsOpen(false);
              setIsLoading(true);
              api
                .createAutoloadSubscription({
                  LoadAmount: state.loadAmount,
                  ThresholdAmount: state.thresholdAmount,
                  VisibleId: visibleId,
                  CustomerId: customerId,
                  Ticket: ticketResponse,
                  DeviceId: userInfo.sessionInstanceId,
                  Username: userName,
                })
                .then((response) => {
                  if (response.data.Success === true) {
                    toNextStep();
                    setIsGetTicketFetching(false);
                    setIsLoading(false);
                  } else {
                    setIsGetTicketFetching(false);
                    setIsLoading(false);
                    const parsedError = isJsonString(response?.data?.Error)
                      ? JSON.parse(response.data.Error)
                      : '';
                    setAutoloadSubscriptionError(parsedError?.Description);
                  }
                });
            }
          }

          function PaymentReceipt(args) {
            PaymentComplete(args);
          }

          function ErrorEvent() {
            setIsOpen(false);
          }
        } else {
          setIsGetTicketFetching(false);
          setIsOpen(false);
          setIsLoading(false);
          const parsedError = isJsonString(response?.data?.Error)
            ? JSON.parse(response.data.Error)
            : '';
          setIsGetTicketFetchError(parsedError?.Description);
        }
      })
      .catch(() => { });
  };

  const getCardImage = (Type) => {
    let cardImage;
    switch (Type.toLowerCase()) {
      case 0:
      case 'v':
      case 'visa':
        cardImage = VisaCardImg;
        break;
      case 1:
      case 'm':
      case 'mastercard':
        cardImage = MasterCard;
        break;
      case 2:
      case 'americanexpress':
        cardImage = AmericanExpress;
        break;
      case 3:
      case 'no':
      case 'discover':
        cardImage = Discover;
        break;
      case 4:
      case 'monerisvault':
        cardImage = MonerisVault;
        break;
      case 5:
      case 'moneristoken':
        cardImage = MonerisToken;
        break;
      case 6:
      case 'interac':
        cardImage = Interac;
        break;
      case 7:
      case 'master':
        cardImage = Master;
        break;
      case 8:
      case 'ax':
      case 'amex':
        cardImage = AmericanExpress;
        break;
      case 9:
      case 'unknown':
        cardImage = CreditCardImg;
        break;
      case 10:
        cardImage = CreditCardImg;
        break;
      case 11:
      case 'c1':
      case 'jcb':
        cardImage = Jcb;
        break;
      case 12:
      case 'd':
      case 'debit':
        cardImage = Interac;
        break;
      case 13:
      case 'se':
      case 'sears':
        cardImage = Sears;
        break;
      case 14:
      case 'up':
      case 'unionpay':
        cardImage = Unipay;
        break;
      default:
        cardImage = CreditCardImg;
    }
    return cardImage;
  };

  return (
    <>
      {isLoading && <Loader />}
      <PaymentModal
        title={fields.paymentDetailsTitle.value}
        cautionMessage={t('monerisPaymentCautionMessage')}
        isModalOpen={isOpen}
        setIsModalOpen={setIsOpen}
        isGetTicketFetching={isGetTicketFetching}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      />
      {!isLoading && (
        <>
          <h1 className="autoload-header" tabIndex="0">
            <Text field={fields.headingPayment} />
          </h1>
          <div className="autoload-setup">
            <div className="autoload-subheader" aria-label={fields.descriptionPaymentA11y.value}>
              <Text field={fields.descriptionPayment} />
            </div>
            <div className="setup-section">
              <div className="section-left">
                <div aria-label={fields.autoloadLabelA11y.value}>
                  <Text field={fields.autoloadLabel} />
                </div>
                <div className="section-amount">
                  <Text field={fields.amountPlaceholder} />
                  {formatDecimal(state.loadAmount)}
                </div>
              </div>
              <div className="section-right" aria-label={fields.dropLabelPaymentA11y.value}>
                <div>
                  <Text field={fields.dropLabelPayment} />
                </div>
                <div className="section-amount">
                  <Text field={fields.amountPlaceholder} />
                  {formatDecimal(state.thresholdAmount)}
                </div>
              </div>

              <div className="section-details-header" aria-label={fields.headingDetailsA11y.value}>
                <Text field={fields.headingDetails} />
              </div>
              <div className="section-details-amount" aria-label={fields.amountA11y.value}>
                <div>
                  <Text field={fields.amount} />
                </div>
                <div className="amount">
                  <Text field={fields.amountPlaceholder} />
                  {formatDecimal(state.loadAmount)}
                </div>
              </div>
            </div>
            <div style={errorStyle}>
              {error ? <Error small title={error} /> : null}
              {autoloadSubscriptionError ? <Error small title={autoloadSubscriptionError} /> : null}
              {isGetTicketFetchError ? <Error small title={isGetTicketFetchError} /> : null}
              {isError ? (
                <Error
                  small
                  title={
                    isSavedPaymentSetForCustomerSelected
                      ? t('savedPaymentCouldNotBeProcessed')
                      : errorContent
                  }
                />
              ) : null}
            </div>

            <Formik
              enableReinitialize
              validateOnBlur={false}
              initialValues={{
                [sharedFormConstants.terms]: false,
                [sharedFormConstants.cardOption]: isSavedPaymentSetForCustomer
                  ? sharedFormConstants.cardOptionSaved
                  : sharedFormConstants.cardOptionDebit,
                loadAmount: state.loadAmount,
                thresholdAmount: state.thresholdAmount,
              }}
              validationSchema={Yup.object().shape({
                [sharedFormConstants.terms]: Yup.boolean().oneOf(
                  [true],
                  t('termsAndConditionsRequired')
                ),
              })}
              onSubmit={(values) => {
                const newState = { ...state };
                setIsLoading(true);
                if (newState.selectedCard === sharedFormConstants.cardOptionSaved) {
                  setSavedPaymentSetForCustomerSelected(true);
                  api
                    .createAutoloadSubscriptionWithSavedPaymentMethod({
                      LoadAmount: state.loadAmount,
                      ThresholdAmount: state.thresholdAmount,
                      VisibleId: visibleId,
                      SavedPaymentMethodId: state.regFormOfPaymRecId,
                      CustomerId: customerId,
                    })
                    .then((result) => {
                      if (result.data.Success) {
                        toNextStep();
                        setIsLoading(false);
                      } else {
                        handleServerSuccessError(result.data.Error, setIsError);
                        setIsLoading(false);
                      }
                    });
                } else {
                  handleMoneris();
                }
              }}
            >
              {({ errors, touched, values, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="autoload-form-wrapper">
                    <div
                      id="radio-group"
                      tabIndex="0"
                      role="application"
                      aria-label={fields.paymentMethodDescriptionA11y.value}
                      className="payment-text"
                    >
                      <Text field={fields.paymentMethodDescription} />
                    </div>
                    <div className="payment-radio">
                      <RadioInput
                        label={fields.creditCardOption.value}
                        a11y={fields.creditCardOption.value}
                        name={sharedFormConstants.cardOption}
                        value={sharedFormConstants.cardOptionDebit}


                        onChange={(e) => {
                          setState({ ...state, selectedCard: e.target.value });
                        }}
                      />
                    </div>
                    {isSavedPaymentSetForCustomer && (
                      <div className="payment-radio">
                        {showCard &&
                          showCard.paymenMethodsList &&
                          showCard.paymenMethodsList.map((card, index) => {
                            if (card.AccountType === 1) {
                              return (
                                <div style={radioStyle}>
                                  <RadioInput
                                    label={`**** ${card.CreditCard.TruncatedPAN.slice(-4)}`}
                                    a11y={`**** ${card.CreditCard.TruncatedPAN.slice(-4)}`}
                                    name={sharedFormConstants.cardOption}
                                    value={sharedFormConstants.cardOptionSaved}
                                    onChange={(e) => {
                                      setState({
                                        ...state,
                                        selectedCard: e.target.value,
                                        regFormOfPaymRecId: card.RegFormOfPaymRecId,
                                      });
                                    }}
                                    cardImgSrc={getCardImage(card.CreditCard.CardType)}
                                    cardImgAlt="Media Icon"
                                    sm
                                  />
                                </div>
                              );
                            }
                            if (card.AccountType === 0) {
                              return (
                                <div style={radioStyle}>
                                  <RadioInput
                                    label={fields.authorizedLabel.value}
                                    a11y={fields.authorizedLabelA11y.value}
                                    name={sharedFormConstants.cardOption}
                                    value={sharedFormConstants.cardOptionSaved}
                                    onChange={(e) => {
                                      setState({
                                        ...state,
                                        selectedCard: e.target.value,
                                        regFormOfPaymRecId: card.RegFormOfPaymRecId,
                                      });
                                    }}
                                  />
                                </div>
                              );
                            }
                          })}
                      </div>
                    )}
                    <Placeholder name="presto-terms-and-conditions" rendering={rendering} />
                    {errors[sharedFormConstants.terms] && touched[sharedFormConstants.terms] && (
                      <div
                        style={{ display: 'block', marginLeft: 22, paddingTop: 3 }}
                        className="error-message"
                      >
                        {errors[sharedFormConstants.terms]}
                      </div>
                    )}
                  </div>

                  {values[sharedFormConstants.cardOption] ===
                    sharedFormConstants.cardOptionDebit && (
                      <div className="redirect-note" aria-label={fields.redirectTextA11y.value}>
                        <Text field={fields.redirectText} />
                      </div>
                    )}

                  <ButtonBlock>
                    <ButtonBlock right>
                      {state.selectedCard === sharedFormConstants.cardOptionDebit ? (
                        <Button type="submit" buttonTextA11y={fields.nextButtonTextA11y.value}>
                          <Text field={fields.nextButtonText} />
                        </Button>
                      ) : (
                        <Button type="submit" buttonTextA11y={fields.confirmButtonTextA11y.value}>
                          <Text field={fields.confirmButtonText} />
                        </Button>
                      )}

                      <Button
                        firstOrder
                        white
                        buttonTextA11y={fields.cancelButtonTextA11y.value}
                        onClick={() => {
                          dispatch(showScreenHeader(true));
                          toPreviousStep();
                        }}
                      >
                        <Text field={fields.cancelButtonText} />
                      </Button>
                    </ButtonBlock>
                  </ButtonBlock>
                </form>
              )}
            </Formik>
          </div>
        </>
      )}

      {configKeys &&
        token &&
        configKeys
          .filter((item) => item.key === 'Cpg.Url')
          .map((configKey) => (
            <form
              method="POST"
              id="frmPayment"
              name="frmpayment"
              style={{ display: 'none' }}
              action={configKey.setting}
            >
              <input type="hidden" name="cc_crypt_type" value="7" id="inputCTID47" />
              <input type="hidden" name="cust_id" id="cust_id" value={userInfo.customerId} />
              <input type="hidden" name="rvarIsAx" value="true" id="inputCTID49" />
              <input type="hidden" name="rvarName" value="" id="inputCTID50" />
              <input type="hidden" name="lang" id="lang" value={getLongLanguageCode()} />
              <input type="hidden" name="LANGUAGE" id="LANGUAGE" value={getLongLanguageCode()} />
              <input type="hidden" name="paymentaggrement" id="paymentaggrement" value="" />
              <input type="hidden" name="nickname" id="nickname" value="" />
              <input
                type="hidden"
                name="ResponseURL"
                id="ResponseURL"
                value={`${getServerUrl()}/apidata/Autoload/CreateAutoloadSubscription?loadAmount=${state.loadAmount
                  }&thresholdAmount=${state.thresholdAmount}&visibleId=${userInfo.selectedCard.visibleId
                  }&token=${token}&returnUrl=${returnUrl}&deviceId=${userInfo.sessionInstanceId}`}
              />
              <input type="hidden" name="isRegisteredPaymentMean" value="true" id="inputCTID56" />
            </form>
          ))}
    </>
  );
}
