import React, { useRef, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import {
  setLanguage,
  togglePageScroll,
  isFrench,
  localstorageService,
  getQueryParams,
  redirectToUrl,
  getCookie,
} from '../../services/utils';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import useMediaWidth from '../../hooks/useMediaWidth';
import HanburgerMenuNavImage from '../../assets/images/HamburgerMenu.svg';
import HanburgerMenuCloseNavImage from '../../assets/images/HamburgerMenuClose.svg';
import { PrestoLink, NavLink } from '../../atoms';
import { HeaderSearch, HeaderSearchMobile, ShoppingCart } from '../../molecules';
import TopNavigationCategoryItem from '../TopNavigation/topNavigationCategoryItem';
import langConstants from '../../constants/lang';

import '../TopNavigationAuthenticated/topNavigationAuthenticated.scss';
import './topNavigationUnAuthenticated.scss';
import {
  addShoppingCartMessage,
  emptyShoppingCartMessage,
  removeNewItemFlag,
  selectCard,
} from '../../redux/user/actions';
import usePrevious from '../../hooks/usePrevious';
import shoppingCartIcon from '../../assets/images/ShoppingCart.svg';
import localStorageKeys from '../../constants/localStorageKeys';
import api from '../../services/api';

const TopNavigationUnAuthenticated = (props) => {

  const isMobile = useMediaWidth();
  const { datasource, contextItem } = props.fields.data ? props.fields.data : {};
  const dropdownRef = useRef(null);
  const [active, setActive] = useState('');
  const secondLevelDropdownRef = useRef(null);
  const [secondLevelactive, setsecondLevelActive] = useDetectOutsideClick(
    secondLevelDropdownRef,
    false
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const urlParams = useParams();
  const queryParams = getQueryParams(useLocation().search);
  const guestUserInfo = useSelector((reduxState) => reduxState.user.guestUserInfoEnabled);

  const mobileMenuRef = useRef(null);
  const [isMenuClicked, setMenuActiv] = useState(false);
  const showMobileMenu = () => setMenuActiv(!isMenuClicked);
  const [langCode, setLangCode] = useState('');
  const dispatch = useDispatch();
  const [guestLogin, setGuestLogin] = useState(false);
  const cartQuantity = useSelector((state) => state.user.shoppingCartQuantity);
  const isNewItem = useSelector((state) => state.user.shoppingCart.isNewItem);
  const shoppingCartRef = useRef(null);
  const location = useLocation();
  const history = useHistory();

  const [isSmartBannerPresent, setIsSmartBannerPresent] = useState(false);
  const [isSkipTranslateHidden, setIsSkipTranslateHidden] = useState(false);

  const [isOpenShoppingCart, setOpenShoppingCart] = useDetectOutsideClick(
    shoppingCartRef,
    false,
    true,
    () => {
      dispatch(emptyShoppingCartMessage());
    }
  );
  const prevValue = usePrevious(isOpenShoppingCart);
  useEffect(() => {
    if (prevValue) {
      dispatch(emptyShoppingCartMessage());
    }
  }, [isOpenShoppingCart]);

  useEffect(() => {
    if (cartQuantity > 0) {
      setGuestLogin(true);
    } else {
      setGuestLogin(false);
    }
  }, [cartQuantity]);

  useEffect(() => {
    if (isNewItem) {
      setOpenShoppingCart(true);
      setTimeout(() => {
        setOpenShoppingCart(false);
        dispatch(removeNewItemFlag());
        dispatch(addShoppingCartMessage(''));
      }, 3000);
    }
  }, [isNewItem]);

  useEffect(() => {
    togglePageScroll(isMenuClicked);
  }, [isMenuClicked]);

  useEffect(() => {
    setIsLoaded(true);
    let language = isFrench() ? langConstants.longFr : langConstants.en;
    setLangCode(language);
  }, []);




  useEffect(() => {
    if (queryParams.signout !== undefined) {
      const queryParams1 = new URLSearchParams(location.search);
      if (queryParams1.has('signout')) {
        let langCode = "/" + getCookie("presto-ui#lang");
        queryParams1.delete('signout');
        history.replace({
          search: queryParams1.toString(),
          pathname: langCode != "/undefined" ? langCode.toString() : '/en',
        });
      }
    }
  }, []);

  const changeLanguage = () => {
    let url = { ...urlParams };
    let searchKeyword = location?.search;
    if (searchKeyword) {
      url = { ...url, sitecoreRoute: `${url?.sitecoreRoute}${searchKeyword}` };
    }
    setLanguage(url);
  };

  const handleDOMChanges = () => {
    const skipTranslateElement = document.querySelector('.skiptranslate');
    const smartBanner = document.querySelector('html.smartbanner-show');
    const mobileMenuWrapper = document.querySelector('.mobile-menu-wrapper');

    setIsSmartBannerPresent(!!smartBanner); // Set state based on smartBanner presence
      setIsSkipTranslateHidden(
          skipTranslateElement &&
          getComputedStyle(skipTranslateElement).getPropertyValue('display') === 'none'
      );
    const targetElement = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd.skiptranslate');
    if (mobileMenuWrapper) {
      if (smartBanner && targetElement && !isSkipTranslateHidden) {
        mobileMenuWrapper.style.top = '176px';
      } else if (document.querySelector('html.smartbanner-show')) {
        mobileMenuWrapper.style.top = '136px';
      } else if (targetElement && !isSkipTranslateHidden) {
        mobileMenuWrapper.style.top = '96px';
      } else {
        mobileMenuWrapper.style.top = '56px'; // Default value if neither is present
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleDOMChanges();
    }, 100); // Check every 1 second

    return () => {
      clearInterval(interval);
    };
  });

  return (
    isLoaded && (
      <div data-e2e-id="top-nav-unauth" role="navigation" className="top-nav-unauthenticated">
        {datasource && (
          <>
            {!isMobile && (
              <div className="header">
                <div className="header-nav-links">
                  <li role="banner">
                    <PrestoLink
                      field={datasource.LogoLink?.jss}
                      image={datasource.parent.Logo.jss}
                      imageClassName="logo-header"
                    />
                  </li>
                  {datasource.children.map((child) => (
                    <TopNavigationCategoryItem navigationItem={child} contextItem={contextItem} />
                  ))}
                </div>
                <div>
                  <HeaderSearch />
                  <li className="nav-french-align">
                    <a
                      role="button"
                      aria-label={props.t('topNavigationLanguageSwitcherA11y')}
                      tabIndex="0"
                      onClick={changeLanguage}
                      onKeyPress={changeLanguage}
                    >
                      {props.t('topNavigationLanguageSwitcher', { lng: langCode })}
                    </a>
                  </li>
                  {guestLogin && (
                    <li>
                      <div
                        className="cart"
                        role="button"
                        onClick={() => setOpenShoppingCart(!isOpenShoppingCart)}
                        onKeyPress={() => setOpenShoppingCart(!isOpenShoppingCart)}
                        tabIndex="0"
                        aria-expanded={isOpenShoppingCart}
                        aria-label={`${props
                          .t('shoppingCartItemsInCartA11y')
                          .replace('{number}', cartQuantity)}`}
                      >
                        <img
                          src={shoppingCartIcon}
                          alt={`${props
                            .t('shoppingCartItemsInCartA11y')
                            .replace('{number}', cartQuantity)}`}
                        />
                        {!!cartQuantity && <span className="cart-quantity">{cartQuantity}</span>}
                      </div>
                      <ShoppingCart
                        forwardRef={shoppingCartRef}
                        cartQuantity={cartQuantity}
                        isOpenShoppingCart={isOpenShoppingCart}
                        closeShoppingCart={() => setOpenShoppingCart(false)}
                        t={props.t}
                      />
                    </li>
                  )}
                  <li className="nav-french-align">
                    <a
                      tabindex="0"
                      onClick={() => api.b2cRegisteredSignIn()}
                      onKeyPress={() => api.b2cRegisteredSignIn()}
                    >
                      {props.t('topNavigationSignIn', { lng: langCode })}
                    </a>
                    {/* <a href={`/${langCode}/sign-in`}>{props.t('topNavigationSignIn')}</a> */}
                  </li>
                </div>
              </div>
            )}

            {isMobile && (
              <ul className="header-mobile">
                <PrestoLink
                  field={datasource.LogoLink?.jss}
                  image={datasource.parent.Logo.jss}
                  imageClassName="logo-header-mobile"
                />
                <div className="mobile-menu-right">
                  {guestLogin && (
                    <>
                      <div
                        className="cart"
                        role="button"
                        tabIndex="0"
                        aria-expanded={isOpenShoppingCart}
                        aria-label={`${props
                          .t('shoppingCartItemsInCartA11y')
                          .replace('{number}', cartQuantity)}`}
                        onClick={() => setOpenShoppingCart(!isOpenShoppingCart)}
                        onKeyPress={() => setOpenShoppingCart(!isOpenShoppingCart)}
                      >
                        <img
                          src={shoppingCartIcon}
                          alt={`${props
                            .t('shoppingCartItemsInCartA11y')
                            .replace('{number}', cartQuantity)}`}
                        />
                        {!!cartQuantity && <span className="cart-quantity">{cartQuantity}</span>}
                      </div>
                      <ShoppingCart
                        forwardRef={shoppingCartRef}
                        cartQuantity={cartQuantity}
                        isOpenShoppingCart={isOpenShoppingCart}
                        closeShoppingCart={() => setOpenShoppingCart(false)}
                        t={props.t}
                      />{' '}
                    </>
                  )}
                  <div className="hamburger">
                    <button
                      type="button"
                      onClick={() => {
                        showMobileMenu();
                      }}
                    >
                      <img
                        alt={
                          !isMenuClicked
                            ? props.t('topNavigationOpenMenuA11y')
                            : props.t('topNavigationCloseMenuA11y')
                        }
                        src={!isMenuClicked ? HanburgerMenuNavImage : HanburgerMenuCloseNavImage}
                      />
                    </button>
                  </div>
                </div>

                <div className={`mobile-menu-wrapper ${isMenuClicked ? 'active' : 'inactive'}`}>
                  <ul
                    ref={mobileMenuRef}
                    className={`dropdown-menu-mobile ${isMenuClicked ? 'active' : 'inactive'}`}
                  >
                    <div
                      className="nav-border"
                      onClick={() => {
                        showMobileMenu();
                      }}
                    />
                    <li className="signin-mobile">
                      <a
                        tabindex="0"
                        onClick={() => api.b2cRegisteredSignIn()}
                        onKeyPress={() => api.b2cRegisteredSignIn()}
                      >
                        {props.t('topNavigationSignIn', { lng: langCode })}
                      </a>
                    </li>
                    <HeaderSearchMobile />
                    {datasource.children.map((child) => (
                      <li>
                        {child.Link && child.children.length === 0 && (
                          <PrestoLink field={child.Link?.jss} onClickCustom={showMobileMenu} />
                        )}
                        {(child.Title || (child.Link && child.children.length > 0)) && (
                          <>
                            {child.Title && (
                              <NavLink
                                title={child.Title?.value}
                                onClick={(title, isActive) => {
                                  if (isActive) {
                                    setActive('');
                                  } else {
                                    setActive(title);
                                  }
                                }}
                                isActive={active === child.Title?.value}
                                isDropdown={active === child.Title?.value}
                                mobile
                              />
                            )}
                            {child.Link && child.children.length > 0 && (
                              <NavLink
                                title={child.Link?.jss?.value?.text}
                                onClick={(title, isActive) => {
                                  if (isActive) {
                                    setActive('');
                                  } else {
                                    setActive(title);
                                  }
                                }}
                                isActive={active === child.Title?.value}
                                isDropdown={active === child.Link?.jss?.value?.text}
                                mobile
                              />
                            )}
                            <ul ref={dropdownRef} className="dropdown-secondlevelmenu-mobile">
                              {child.children?.map((grandchild) => (
                                <li>
                                  {grandchild.Link && grandchild.children.length === 0 && (
                                    <PrestoLink
                                      field={grandchild.Link?.jss}
                                      onClickCustom={showMobileMenu}
                                    />
                                  )}
                                  {(grandchild.Title ||
                                    (grandchild.Link && grandchild.children.length > 0)) && (
                                      <>
                                        {grandchild.Title && (
                                          <NavLink
                                            title={grandchild.Title?.value}
                                            onClick={setsecondLevelActive}
                                            isDropdown={secondLevelactive === grandchild.Title?.value}
                                            mobile
                                          />
                                        )}
                                        {grandchild.Link && grandchild.children.length > 0 && (
                                          <NavLink
                                            title={grandchild.Link?.jss?.value?.text}
                                            onClick={setsecondLevelActive}
                                            isDropdown={
                                              secondLevelactive === grandchild.Link?.jss?.value?.text
                                            }
                                            mobile
                                          />
                                        )}
                                        <ul
                                          ref={secondLevelDropdownRef}
                                          className="dropdown-thirdlevelmenu-mobile"
                                        >
                                          {grandchild.children?.map((grandgrandchild) => (
                                            <li>
                                              <PrestoLink
                                                field={grandgrandchild.Link?.jss}
                                                onClickCustom={showMobileMenu}
                                              />
                                            </li>
                                          ))}
                                        </ul>
                                      </>
                                    )}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </li>
                    ))}
                    <li>
                      <a
                        role="button"
                        tabIndex="0"
                        onClick={changeLanguage}
                        onKeyPress={changeLanguage}
                      >
                        {props.t('topNavigationLanguageSwitcherMobile', { lng: langCode })}
                      </a>
                    </li>
                  </ul>
                </div>
              </ul>
            )}
          </>
        )}
      </div>
    )
  );
};

export default withTranslation()(TopNavigationUnAuthenticated);
