import React, { useState, useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import sharedFormConstants from '../../constants/sharedFormConstants';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import { CardBlock, Error, Header, Select, PaymentModal } from '../../molecules';
import api from '../../services/api';
import { Button, ButtonBlock, InlineError, Loader, RadioInput } from '../../atoms';
import {
  formatDecimalWithCurrency,
  getLongLanguageCode,
  getServerUrl,
  redirectToUrl,
  isJsonString,
} from '../../services/utils';
import { setManageAutoRenewMode } from '../../redux/products/actions';
import CreditCardImg from '../../assets/images/credit_card.png';
import AmericanExpress from '../../assets/images/Latest_American_Express.png';
import Interac from '../../assets/images/card_interac.png';
import MasterCard from '../../assets/images/mastercard_new.png';
import VisaCardImg from '../../assets/images/Visa_latest.png';
import Sears from '../../assets/images/Sears.png';
import MonerisVault from '../../assets/images/MonerisVault.png';
import MonerisToken from '../../assets/images/MonerisToken.png';
import Master from '../../assets/images/Master.png';
import Jcb from '../../assets/images/Jcb.png';
import Discover from '../../assets/images/Discover.png';
import Debit from '../../assets/images/Debit.png';
import Unipay from '../../assets/images/Unipay.png';

const ManageAutoRenew1 = ({
  t,
  fields,
  instance,
  userInfo,
  toCheckoutStep2,
  queryParams,
  TCrendering,
  configKeys,
  backToTransitPassesButtonLink,
}) => {
  const dispatch = useDispatch();
  const { Heading, BillingDateLabel, BillingDateLabelA11y } = fields;
  const [isSavedPaymentSetForCustomer, setSavedPaymentSetForCustomer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');
  const [isError, setIsError] = useState(null);
  const [calendarOptions, setCalendarOptions] = useState(null);
  const [checkoutData, setCheckoutData] = useState({});
  const [currentBillingMonth, setCurrentBillingMonth] = useState('');
  const [currentBillingYear, setCurrentBillingYear] = useState('');
  const [state, setState] = useState({});
  const customerId = userInfo.customerId;
  const visibleId = userInfo.selectedCard.visibleId;
  const userName = userInfo.userName;
  const [isOpen, setIsOpen] = useState(false);
  const [isGetTicketFetching, setIsGetTicketFetching] = useState(false);
  const [showCard, setShowCard] = useState({ paymenMethodsList: [] });
  const [isGetTicketFetchError, setIsGetTicketFetchError] = useState('');
  const [autorenewSubscriptionError, setAutorenewSubscriptionError] = useState('');
  const [initialMonth, setInitialMonth] = useState('');
  const radioStyle = {
    margin: '16px 0px 16px 0px',
  };
  useEffect(() => {
    if (instance?.Product) {
      setCheckoutData({
        serviceProvider: instance?.Product.ProductServiceProvider.Id,
        productId: instance?.Product.ProductId,
        name: instance?.Product.ProductName,
        subTotal: instance?.Product.ProductPrice,
        validityStartDate: instance?.StartDateTime,
        validityEndDate: instance?.ExpiryDateTime,
        oldEndDate: instance?.ExpiryDateTime,
        autorenew: {
          month: currentBillingMonth,
          year: currentBillingYear,
          endDate:
            !currentBillingYear && !currentBillingMonth
              ? null : currentBillingYear === 1900
                ? `${currentBillingYear}-${currentBillingMonth}-1 00:00:00Z`
                : `${currentBillingYear}-${currentBillingMonth}-${new Date(
                  currentBillingYear,
                  parseInt(currentBillingMonth, 10),
                  0
                ).getDate()} 00:00:00Z`,
        },
        isManageAutoRenew: true,
      });
    }
  }, [currentBillingMonth, currentBillingYear]);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  function isMonthValid(selectedYear, monthOption) {
    if (selectedYear > currentYear) {
      return true;
    }
    return monthOption.value > currentMonth;
  }
  useEffect(() => {
    if (
      Object.prototype.hasOwnProperty.call(queryParams, 'error') &&
      (queryParams.error === '' || queryParams.error === 'true')
    ) {
      setIsError(true);
    }
  }, [queryParams]);

  useEffect(() => {
    setIsLoading(true);
    document.body.scrollTop = 0;
    api
      .getRegisterPaymentMeansForCustomer({
        CustomerId: customerId,
        VisibleId: visibleId,
      })
      .then((response) => {
        if (response.data?.Success) {
          if (response.data.RegisterPaymentMeansList[0]?.RegFormOfPaymRecId) {
            setSavedPaymentSetForCustomer(true);
            setShowCard({
              paymenMethodsList: response.data.RegisterPaymentMeansList,
            });
            setIsLoading(false);
            //To pre-select radio button on pageload
            var id = `**** ${response.data.RegisterPaymentMeansList[0].CreditCard.TruncatedPAN.slice(
              -4
            )}`;
            if (document.getElementById(id) != null) {
              document.getElementById(id).checked = true;
            }
            setState({
              ...state,
              selectedCard: sharedFormConstants.cardOptionSaved,
              regFormOfPaymRecId: response.data.RegisterPaymentMeansList[0].RegFormOfPaymRecId,
            });
          } else {
            setIsLoading(false);
            var id = fields.debitCardOptionLabel.value;
            document.getElementById(id).checked = true;
            setState({
              ...state,
              selectedCard: sharedFormConstants.cardOptionDebit,
            });
          }
        } else {
          setIsLoading(false);
        }
      });
    api.getAutorenewCalendar().then((calendarResponse) => {
      if (!calendarResponse.data) return;

      const opts = {};
      Object.keys(calendarResponse.data).forEach((language) => {
        const dataPerLanguage = calendarResponse.data[language];

        const yearOpt = [...Array(dataPerLanguage.amountOfFutureYears + 1).keys()]
          .map((i) => i + currentYear)
          .map((year) => {
            return { value: year, label: year };
          });

        const monthOpt = Object.keys(dataPerLanguage.months).map((key) => {
          return { value: key, label: dataPerLanguage.months[key] };
        });
        opts[language] = { yearOpt, monthOpt };
      });
      setCalendarOptions(opts);
    });
    api
      .getAccessToken()
      .then((accessToken) => setToken(accessToken))
      .catch(() => {
        setToken('');
      });
  }, []);
  const postCpgAdHocPaymentForm = async () => {
    document.getElementById('frmPayment').submit();
  };
  const location = useLocation();
  const returnUrl = `${getServerUrl()}${location.pathname}`;

  const getTermsAndConditions = () => {
    const rendering1 = cloneDeep(TCrendering);
    const rendering2 = cloneDeep(TCrendering);
    rendering1.placeholders['presto-terms-and-conditions'].splice(1, 1);
    rendering2.placeholders['presto-terms-and-conditions'].splice(0, 1);
    return rendering1;
  };
  const monerisEnvironment =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.Environment').map((config) => config.setting);

  const handleMoneris = () => {
    setIsOpen(true);
    setIsGetTicketFetching(true);
    setIsLoading(false);
    api
      .getTicket({
        paymentProfileType: 'TOKENIZE',
        OrderNumber: '',
        customerId: customerId,
        VisibleId: visibleId,
        userName: userName,
        Amount: instance?.Product.ProductPrice,
      })
      .then((response) => {
        if (response.data.Success === true) {
          var myCheckout = new window.monerisCheckout();
          myCheckout.setMode(monerisEnvironment[0]);
          myCheckout.setCheckoutDiv('monerisCheckout');
          myCheckout.setCallback('page_loaded', PageLoad);
          myCheckout.setCallback('cancel_transaction', CancelTransaction);
          myCheckout.setCallback('error_event', ErrorEvent);
          myCheckout.setCallback('payment_receipt', PaymentReceipt);
          myCheckout.setCallback('payment_complete', PaymentComplete);
          myCheckout.startCheckout(response.data.TicketResponse.ticket);
          const ticketResponse = response.data.TicketResponse.ticket;

          function PageLoad() {
            setIsGetTicketFetching(false);
            setIsLoading(false);
          }

          function CancelTransaction() {
            setIsOpen(false);
          }

          function PaymentComplete(args) {
            document.getElementById('monerisCheckout').innerHTML = '';
            document.getElementById('monerisCheckout').style = '';
            const parsedData = isJsonString(args) ? JSON.parse(args) : '';
            if (parsedData?.response_code !== '001') {
              setIsOpen(false);
              setAutorenewSubscriptionError(t('monerisPaymentError'));
            } else {
              setIsOpen(false);
              setIsLoading(true);
              api
                .updateAutorenewSubscriptionByMoneris({
                  customerId: customerId,
                  visibleId: visibleId,
                  mediaId: userInfo.selectedCard.mediaId,
                  productId: instance?.Product.ProductId,
                  year: currentBillingYear,
                  month: parseInt(currentBillingMonth),
                  deviceId: userInfo.sessionInstanceId,
                  ticket: ticketResponse,
                  userName: userName,
                  token: token,
                })
                .then((response) => {
                  if (response.data.Success === true) {
                    dispatch(
                      setManageAutoRenewMode({
                        visibility: true,
                        instance,
                        TCrendering,
                        checkoutData,
                      })
                    );
                    setIsGetTicketFetching(false);
                    setIsLoading(false);
                    toCheckoutStep2();
                  } else {
                    setIsGetTicketFetching(false);
                    setIsLoading(false);
                    const parsedError = isJsonString(response?.data?.Error)
                      ? JSON.parse(response.data.Error)
                      : '';
                    setAutorenewSubscriptionError(parsedError?.Description);
                  }
                });
            }
          }

          function PaymentReceipt(args) {
            PaymentComplete(args);
          }

          function ErrorEvent() {
            setIsOpen(false);
          }
        } else {
          setIsGetTicketFetching(false);
          setIsOpen(false);
          setIsLoading(false);
          const parsedError = isJsonString(response?.data?.Error)
            ? JSON.parse(response.data.Error)
            : '';
          setIsGetTicketFetchError(parsedError?.Description);
        }
      })
      .catch(() => { });
  };

  const getCardImage = (Type) => {
    let cardImage;
    switch (Type.toLowerCase()) {
      case 0:
      case 'v':
      case 'visa':
        cardImage = VisaCardImg;
        break;
      case 1:
      case 'm':
      case 'mastercard':
        cardImage = MasterCard;
        break;
      case 2:
      case 'americanexpress':
        cardImage = AmericanExpress;
        break;
      case 3:
      case 'no':
      case 'discover':
        cardImage = Discover;
        break;
      case 4:
      case 'monerisvault':
        cardImage = MonerisVault;
        break;
      case 5:
      case 'moneristoken':
        cardImage = MonerisToken;
        break;
      case 6:
      case 'interac':
        cardImage = Interac;
        break;
      case 7:
      case 'master':
        cardImage = Master;
        break;
      case 8:
      case 'ax':
      case 'amex':
        cardImage = AmericanExpress;
        break;
      case 9:
      case 'unknown':
        cardImage = CreditCardImg;
        break;
      case 10:
        cardImage = CreditCardImg;
        break;
      case 11:
      case 'c1':
      case 'jcb':
        cardImage = Jcb;
        break;
      case 12:
      case 'd':
      case 'debit':
        cardImage = Interac;
        break;
      case 13:
      case 'se':
      case 'sears':
        cardImage = Sears;
        break;
      case 14:
      case 'up':
      case 'unionpay':
        cardImage = Unipay;
        break;
      default:
        cardImage = CreditCardImg;
    }
    return cardImage;
  };

  var errors = {};
  const formRef1 = useRef(null);
  const formRef2 = useRef(null);

  const validate1 = (values) => {
    if (values.year !== 1900 && values.month === '') errors.month = "Please select a month";
    else delete errors.month;
    return errors;
  };
  const validate2 = (values) => {
    if (!values[sharedFormConstants.terms]) errors[sharedFormConstants.terms] = fields.TermsandConditionErrorMessage.value;
    else delete errors.terms;
    if (currentBillingYear !== 1900 && currentBillingMonth == '') errors.month = "Please Select a Month";
    else delete errors.month;
    return errors;
  };

  return [
    isLoading ? <Loader /> : null,
    <div className="manage-autoRenew1">
      <PaymentModal
        title={fields.PaymentDetailsTitle.value}
        cautionMessage={t('monerisPaymentCautionMessage')}
        isModalOpen={isOpen}
        setIsModalOpen={setIsOpen}
        isGetTicketFetching={isGetTicketFetching}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      />
      <div className="manage-autoRenew1--title">
        <Header aria-label={Heading}>
          <Text field={Heading} />
        </Header>
      </div>
      <div className="manage-autoRenew1--summary">
        <div className="billing-date">
          <p aria-label={BillingDateLabelA11y.value}>{BillingDateLabel.value}</p>
          <p>{moment(instance?.ExpiryDateTime).utc().format('D MMM YYYY')}</p>
        </div>
        <p>{fields.AutorenewDescription.value}</p>
        <div className="end-date-field">
          <Formik
            initialValues={{
              month: moment(instance?.ExpiryDateTime).utc().format('M'),
              year: Number(moment(instance?.ExpiryDateTime).utc().format('YYYY')),
            }}
            validate={validate1}
            innerRef={formRef1}
          >
            {({ errors, touched, handleSubmit, values, setFieldValue }) => {
              setInitialMonth(moment(instance?.ExpiryDateTime).utc().format('M'));
              if (values.year === 1900 && values.month === "") {
                setCurrentBillingMonth(initialMonth);
              }
              else {
                setCurrentBillingMonth(values.month)
              }
              if (values.year !== currentBillingYear) {
                setCurrentBillingYear(values.year);
              }
              return (
                <form onSubmit={handleSubmit}>
                  <div className="end-date-field--label">
                    <Text field={fields.DateLabel} />
                  </div>
                  <div className="autonrenew1-dropdowns">
                    <div className="manage-autoRenew1-selectbox1-pad ">
                      <Select
                        onClose={() => {
                          setFieldValue('month', '');
                        }}
                        name="year"
                        options={
                          calendarOptions ? calendarOptions[getLongLanguageCode()]?.yearOpt : []
                        }
                        errors={errors}
                        touched={touched}
                        labelA11y={fields.YearLabel.value}
                        defaultValue={Number(moment(instance?.ExpiryDateTime).utc().format('YYYY'))}
                        defaultLabel={Number(moment(instance?.ExpiryDateTime).utc().format('YYYY'))}
                      />
                    </div>
                    <div hidden={values.year === 1900} className="manage-autoRenew1-selectbox2-pad ">
                      <Select
                        name="month"
                        options={
                          calendarOptions
                            ? calendarOptions[getLongLanguageCode()]?.monthOpt.filter((month) => {
                              return isMonthValid(values.year, month);
                            })
                            : []
                        }
                        isDisabled={!values.year}
                        errors={errors}
                        touched={touched}
                        labelA11y={fields.MonthLabel.value}
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        initialValues={{
          [sharedFormConstants.terms]: false,
          [sharedFormConstants.cardOption]: isSavedPaymentSetForCustomer
            ? sharedFormConstants.cardOptionSaved
            : sharedFormConstants.cardOptionDebit,
          terms: false,
        }}
        validate={validate2}
        innerRef={formRef2}
        onSubmit={(values) => {
          const newState = { ...state };
          setIsLoading(true);
          if (newState.selectedCard === sharedFormConstants.cardOptionSaved) {
            api
              .manageAutorenewSubscription({
                VisibleId: userInfo.selectedCard.visibleId,
                CustomerId: userInfo.customerId,
                MediaId: userInfo.selectedCard.mediaId,
                ProductId: instance?.Product.ProductId,
                RegFormOfPaymRecId: state.regFormOfPaymRecId,
                PaymentGatewayResponse: null,
                Month: currentBillingMonth,
                Year: currentBillingYear,
              })
              .then((result) => {
                if (result.data.Success) {
                  dispatch(
                    setManageAutoRenewMode({
                      visibility: true,
                      instance,
                      TCrendering,
                      checkoutData,
                    })
                  );
                  setIsLoading(false);
                  toCheckoutStep2();
                } else {
                  handleServerSuccessError(result.data.Error, setIsError);
                  setIsLoading(false);
                }
              })
              .catch(() => {
                setIsLoading(false);
              });
          } else {
            handleMoneris();
          }
        }}
      >
        {({ errors, touched, handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              {isError ? <Error small title={fields.ErrorMessage.value} /> : null}
              {autorenewSubscriptionError ? (
                <Error small title={autorenewSubscriptionError} />
              ) : null}
              {isGetTicketFetchError ? <Error small title={isGetTicketFetchError} /> : null}
              <CardBlock
                title={fields.PaymentMethodTitle.value}
                cssClass="cardComponent-top-margin"
              >
                <div
                  className="payment-text"
                  id="radio-group"
                  role="application"
                  aria-label={fields.PaymentMethodSubTitleA11y.value}
                >
                  <Text field={fields.PaymentMethodSubTitle} />
                </div>
                <div className="payment-radio">
                  <RadioInput
                    label={fields.AdhocPaymentLabel.value}
                    a11y={fields.AdhocPaymentLabelA11y.value}
                    name={sharedFormConstants.cardOption}
                    value={sharedFormConstants.cardOptionDebit}
                    onChange={(e) => {
                      setState({ ...state, selectedCard: e.target.value });
                    }}
                  />
                </div>
                {isSavedPaymentSetForCustomer && (
                  <div className="payment-radio">
                    {showCard &&
                      showCard.paymenMethodsList &&
                      showCard.paymenMethodsList.map((card, index) => {
                        if (card.AccountType === 1) {
                          return (
                            <div style={radioStyle}>
                              <RadioInput
                                label={`**** ${card.CreditCard.TruncatedPAN.slice(-4)}`}
                                a11y={`**** ${card.CreditCard.TruncatedPAN.slice(-4)}`}
                                name={sharedFormConstants.cardOption}
                                value={sharedFormConstants.cardOptionSaved}
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    selectedCard: e.target.value,
                                    regFormOfPaymRecId: card.RegFormOfPaymRecId,
                                  });
                                }}
                                cardImgSrc={getCardImage(card.CreditCard.CardType)}
                                cardImgAlt="Media Icon"
                                sm
                              />
                            </div>
                          );
                        }
                        if (card.AccountType === 0) {
                          return (
                            <div style={radioStyle}>
                              <RadioInput
                                label={fields.AuthorizedLabel.value}
                                a11y={fields.AuthorizedLabelA11y}
                                name={sharedFormConstants.cardOption}
                                value={sharedFormConstants.cardOptionSaved}
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    selectedCard: e.target.value,
                                    regFormOfPaymRecId: card.RegFormOfPaymRecId,
                                  });
                                }}
                              />
                            </div>
                          );
                        }
                      })}
                  </div>
                )}
                <div className="checkout-terms-block">
                  <Placeholder
                    name="presto-terms-and-conditions"
                    rendering={getTermsAndConditions()}
                  />
                  {errors[sharedFormConstants.terms] && touched[sharedFormConstants.terms] && (
                    <InlineError cssClass="checkbox-error">
                      {errors[sharedFormConstants.terms]}
                    </InlineError>
                  )}
                </div>
                <div className="checkout-payment-total">
                  <Text field={fields.PaymentAmountLabel} />
                  <span>{formatDecimalWithCurrency(instance?.Product?.ProductPrice)}</span>
                </div>
              </CardBlock>
              {state.selectedCard === sharedFormConstants.cardOptionDebit ? (
                <div className="checkout-subtext">
                  <Text field={fields.monerisRedirectText} />
                </div>
              ) : null}
              <ButtonBlock>
                <ButtonBlock right>
                  {state.selectedCard === sharedFormConstants.cardOptionDebit ? (
                    <Button type="submit" buttonTextA11y={fields.NextCTAA11y.value}>
                      <Text field={fields.NextCTA} />
                    </Button>
                  ) : (
                    <Button type="submit" onClick={() => { formRef1.current.setFieldTouched('month', true) }} buttonTextA11y={fields.ConfirmButtonTextA11y}>
                      <Text field={fields.ConfirmButtonText} />
                    </Button>
                  )}
                  <Button
                    white
                    firstOrder
                    onClick={() => redirectToUrl(backToTransitPassesButtonLink)}
                  >
                    <Text field={fields.GoBackCTA} />
                  </Button>
                </ButtonBlock>
              </ButtonBlock>
            </form>
          );
        }}
      </Formik>
    </div>,
    configKeys &&
    token &&
    configKeys
      .filter((item) => item.key === 'Cpg.Url')
      .map((configKey) => (
        <form
          method="POST"
          id="frmPayment"
          name="frmpayment"
          style={{ display: 'none' }}
          action={configKey.setting}
        >
          <input type="hidden" name="cc_crypt_type" value="7" id="inputCTID47" />
          <input type="hidden" name="cust_id" id="cust_id" value={userInfo.customerId} />
          <input type="hidden" name="rvarIsAx" value="true" id="inputCTID49" />
          <input type="hidden" name="rvarName" value="" id="inputCTID50" />
          <input type="hidden" name="lang" id="lang" value={getLongLanguageCode()} />
          <input type="hidden" name="LANGUAGE" id="LANGUAGE" value={getLongLanguageCode()} />
          <input type="hidden" name="paymentaggrement" id="paymentaggrement" value="" />
          <input type="hidden" name="nickname" id="nickname" value="" />
          <input
            type="hidden"
            name="ResponseURL"
            id="ResponseURL"
            value={`${getServerUrl()}/apidata/Autorenew/UpdateAutorenewSubscriptionByMoneris?visibleId=${userInfo.selectedCard.visibleId
              }&mediaId=${userInfo.selectedCard.mediaId}&token=${token}&productId=${instance?.Product?.ProductId
              }&year=${currentBillingYear}&month=${currentBillingMonth}&returnUrl=${returnUrl}&serializedData=${encodeURI(
                JSON.stringify(checkoutData)
              )}&deviceId=${userInfo.sessionInstanceId}`}
          />
          <input type="hidden" name="isRegisteredPaymentMean" value="true" id="inputCTID56" />
        </form>
      )),
  ];
};

export default ManageAutoRenew1;
