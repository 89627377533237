import React, { useState, useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import { Text, RichText, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import api from '../../services/api';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import sharedFormConstants from '../../constants/sharedFormConstants';
import {
  formatDecimalWithCurrency,
  getServerUrl,
  getLongLanguageCode,
  redirectToUrl,
  formatDate,
  isJsonString,
} from '../../services/utils';
import { RadioInput, ButtonBlock, Button, InlineError, Loader } from '../../atoms';
import { Error, CardBlock, Note, CheckoutGrid, Header, PaymentModal } from '../../molecules';

import './setAutorenew.scss'; // TODO refactor - mostly copy from Checkout

import CreditCardImg from '../../assets/images/credit_card.png';
import AmericanExpress from '../../assets/images/Latest_American_Express.png';
import Interac from '../../assets/images/card_interac.png';
import MasterCard from '../../assets/images/mastercard_new.png';
import VisaCardImg from '../../assets/images/Visa_latest.png';
import Sears from '../../assets/images/Sears.png';
import MonerisVault from '../../assets/images/MonerisVault.png';
import MonerisToken from '../../assets/images/MonerisToken.png';
import Master from '../../assets/images/Master.png';
import Jcb from '../../assets/images/Jcb.png';
import Discover from '../../assets/images/Discover.png';
import Debit from '../../assets/images/Debit.png';
import Unipay from '../../assets/images/Unipay.png';

const SetAutorenew1 = ({
  fields,
  t,
  state,
  setState,
  errorContent,
  toCheckoutStep2,
  backToTransitPassesButtonLink,
  userInfo,
  configKeys,
  rendering,
}) => {
  const checkoutData = state.checkoutData
    ? state.checkoutData
    : {
      serviceProvider: state.serviceProviderId,
      productId: state.product.ProductId,
      name: state.product.ProductName,
      subTotal: state.product.ProductPrice,
      validityStartDate: state.product.ValidityStartDate,
      validityEndDate: state.product.ValidityEndDate,
      autorenew: {
        ...state.autorenew,
      },
    };

  const checkoutItem = {
    ...checkoutData,
    visibleId: userInfo.selectedCard.visibleId,
    autorenew: {
      ...checkoutData.autorenew,
      endDate:
        checkoutData.autorenew.year && checkoutData.autorenew.month
          ? `${checkoutData.autorenew.year}-${checkoutData.autorenew.month}-${new Date(
            checkoutData.autorenew.year,
            parseInt(checkoutData.autorenew.month, 10),
            0
          ).getDate()} 00:00:00Z`
          : null,
    },
  };

  const [isSavedPaymentSetForCustomer, setSavedPaymentSetForCustomer] = useState(false);
  const [isSavedPaymentSetForCustomerSelected, setSavedPaymentSetForCustomerSelected] = useState(
    false
  );
  const [regFormOfPaymRecId, setRegFormOfPaymRecId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isGetTicketFetching, setIsGetTicketFetching] = useState(false);
  const [showCard, setShowCard] = useState({ paymenMethodsList: [] });
  const [isGetTicketFetchError, setIsGetTicketFetchError] = useState('');
  const [autorenewSubscriptionError, setAutorenewSubscriptionError] = useState('');

  const customerId = userInfo.customerId;
  const visibleId = userInfo.selectedCard.visibleId;
  const userName = userInfo.userName;

  const radioStyle = {
    margin: '16px 0px 16px 0px',
  };

  useEffect(() => {
    setIsLoading(true);
    document.body.scrollTop = 0;
    api
      .getRegisterPaymentMeansForCustomer({
        CustomerId: customerId,
        VisibleId: visibleId,
      })
      .then((response) => {
        if (response.data?.Success) {
          if (response.data.RegisterPaymentMeansList[0]?.RegFormOfPaymRecId) {
            setSavedPaymentSetForCustomer(true);
            setShowCard({
              paymenMethodsList: response.data.RegisterPaymentMeansList,
            });
            setIsLoading(false);
            //To pre-select radio button on pageload

            var id = fields.debitCardOptionLabel.value;
            document.getElementById(id).checked = true;
            setState({
              ...state,
              selectedCard: sharedFormConstants.cardOptionDebit,
            });

            /*
            var id = `**** ${response.data.RegisterPaymentMeansList[0].CreditCard.TruncatedPAN.slice(
              -4
            )}`;
            if (document.getElementById(id) != null) {
              document.getElementById(id).checked = true;
            }
            setState({
              ...state,
              selectedCard: sharedFormConstants.cardOptionSaved,
              regFormOfPaymRecId: response.data.RegisterPaymentMeansList[0].RegFormOfPaymRecId,
            }); 
            */
          } else {
            setIsLoading(false);
            var id = fields.debitCardOptionLabel.value;
            document.getElementById(id).checked = true;
            setState({
              ...state,
              selectedCard: sharedFormConstants.cardOptionDebit,
            });
          }
        } else {
          setIsLoading(false);
        }
      });

    api
      .getAccessToken()
      .then((accessToken) => setToken(accessToken))
      .catch(() => {
        setToken('');
      });
  }, []);

  const monerisEnvironment =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.Environment').map((config) => config.setting);

  const postCpgAdHocPaymentForm = async () => {
    document.getElementById('frmPayment').submit();
  };

  const [error, setIsError] = useState('');
  const location = useLocation();
  const returnUrl = `${getServerUrl()}${location.pathname}`;

  const handleMoneris = () => {
    setIsOpen(true);
    setIsGetTicketFetching(true);
    setIsLoading(false);
    api
      .getTicket({
        paymentProfileType: 'TOKENIZE',
        OrderNumber: '',
        customerId: customerId,
        VisibleId: visibleId,
        userName: userName,
        Amount: state.product.ProductPrice,
      })
      .then((response) => {
        if (response.data.Success === true) {
          var myCheckout = new window.monerisCheckout();
          myCheckout.setMode(monerisEnvironment[0]);
          myCheckout.setCheckoutDiv('monerisCheckout');
          myCheckout.setCallback('page_loaded', PageLoad);
          myCheckout.setCallback('cancel_transaction', CancelTransaction);
          myCheckout.setCallback('error_event', ErrorEvent);
          myCheckout.setCallback('payment_receipt', PaymentReceipt);
          myCheckout.setCallback('payment_complete', PaymentComplete);
          myCheckout.startCheckout(response.data.TicketResponse.ticket);
          const ticketResponse = response.data.TicketResponse.ticket;

          function PageLoad() {
            setIsGetTicketFetching(false);
            setIsLoading(false);
          }

          function CancelTransaction() {
            setIsOpen(false);
          }

          function PaymentComplete(args) {
            document.getElementById('monerisCheckout').innerHTML = '';
            document.getElementById('monerisCheckout').style = '';
            const parsedData = isJsonString(args) ? JSON.parse(args) : '';
            if (parsedData?.response_code !== '001') {
              setIsOpen(false);
              setAutorenewSubscriptionError(t('monerisPaymentError'));
            } else {
              setIsOpen(false);
              setIsLoading(true);
              api
                .createAutorenewSubscription({
                  CustomerId: customerId,
                  VisibleId: visibleId,
                  MediaId: userInfo.selectedCard.mediaId,
                  ProductId: state.product.ProductId,
                  Year: state.autorenew.year,
                  Month: parseInt(state.autorenew.month),
                  DeviceId: userInfo.sessionInstanceId,
                  Ticket: ticketResponse,
                  Username: userName,
                })
                .then((response) => {
                  if (response.data.Success === true) {
                    toCheckoutStep2();
                    setIsGetTicketFetching(false);
                    setIsLoading(false);
                  } else {
                    setIsGetTicketFetching(false);
                    setIsLoading(false);
                    const parsedError = isJsonString(response?.data?.Error)
                      ? JSON.parse(response.data.Error)
                      : '';
                    setAutorenewSubscriptionError(parsedError?.Description);
                  }
                });
            }
          }

          function PaymentReceipt(args) {
            PaymentComplete(args);
          }

          function ErrorEvent() {
            setIsOpen(false);
          }
        } else {
          setIsGetTicketFetching(false);
          setIsOpen(false);
          setIsLoading(false);
          const parsedError = isJsonString(response?.data?.Error)
            ? JSON.parse(response.data.Error)
            : '';
          setIsGetTicketFetchError(parsedError?.Description);
        }
      })
      .catch(() => { });
  };

  const getCardImage = (Type) => {
    let cardImage;
    switch (Type.toLowerCase()) {
      case 0:
      case 'v':
      case 'visa':
        cardImage = VisaCardImg;
        break;
      case 1:
      case 'm':
      case 'mastercard':
        cardImage = MasterCard;
        break;
      case 2:
      case 'americanexpress':
        cardImage = AmericanExpress;
        break;
      case 3:
      case 'no':
      case 'discover':
        cardImage = Discover;
        break;
      case 4:
      case 'monerisvault':
        cardImage = MonerisVault;
        break;
      case 5:
      case 'moneristoken':
        cardImage = MonerisToken;
        break;
      case 6:
      case 'interac':
        cardImage = Interac;
        break;
      case 7:
      case 'master':
        cardImage = Master;
        break;
      case 8:
      case 'ax':
      case 'amex':
        cardImage = AmericanExpress;
        break;
      case 9:
      case 'unknown':
        cardImage = CreditCardImg;
        break;
      case 10:
        cardImage = CreditCardImg;
        break;
      case 11:
      case 'c1':
      case 'jcb':
        cardImage = Jcb;
        break;
      case 12:
      case 'd':
      case 'debit':
        cardImage = Interac;
        break;
      case 13:
      case 'se':
      case 'sears':
        cardImage = Sears;
        break;
      case 14:
      case 'up':
      case 'unionpay':
        cardImage = Unipay;
        break;
      default:
        cardImage = CreditCardImg;
    }
    return cardImage;
  };

  const getTermsAndConditions = () => {
    const rendering1 = cloneDeep(rendering);
    const rendering2 = cloneDeep(rendering);
    rendering1.placeholders['presto-terms-and-conditions'].splice(1, 1);
    rendering2.placeholders['presto-terms-and-conditions'].splice(0, 1);
    return rendering1;
  };

  return (
    <>
      {isLoading && <Loader />}
      <PaymentModal
        title={fields.paymentDetailsTitle.value}
        cautionMessage={t('monerisPaymentCautionMessage')}
        isModalOpen={isOpen}
        setIsModalOpen={setIsOpen}
        isGetTicketFetching={isGetTicketFetching}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      />
      {!isLoading && (
        <div className="autorenew">
          <Header centered titleA11y={fields.headingA11y.value}>
            <Text field={fields.heading} />
          </Header>

          <div className="header-subTitle">
            <Text field={fields.autorenewCheckoutTitle} />
          </div>

          <CheckoutGrid
            fields={fields}
            items={[checkoutItem]}
            fareMedia={{ visibleId: userInfo.selectedCard.visibleId }}
            hideRemove={true}
          />

          <div className="autorenew-agreement">
            <div className="autorenew-agreement-title">
              <Text field={fields.autorenewAgreementPeriodTitle} />
            </div>
            {checkoutItem.autorenew.endDate ? (
              <div>
                {`${formatDate(new Date())} - ${formatDate(checkoutItem.autorenew.endDate)}`}
              </div>
            ) : null}

            <div className="autorenew-agreement-note">
              {fields.autorenewAgreementPeriodDescription.value
                .replace(
                  '{monthly-payment-amount}',
                  formatDecimalWithCurrency(checkoutItem.subTotal)
                )
                .replace('{transit-pass-name}', checkoutItem.name)}
            </div>
          </div>

          <Note
            Title={() => (
              <div aria-label={fields.addAutorenewNoteTitleA11y.value}>
                <RichText field={fields.addAutorenewNoteTitle} />
              </div>
            )}
          />
          <div className="error-autorenew">
            {error ? <Error small title={error} /> : null}
            {autorenewSubscriptionError ? <Error small title={autorenewSubscriptionError} /> : null}
            {isGetTicketFetchError ? <Error small title={isGetTicketFetchError} /> : null}
            {state.isSuccess === false ? (
              <Error
                small
                title={
                  isSavedPaymentSetForCustomerSelected
                    ? t('savedPaymentCouldNotBeProcessed')
                    : errorContent
                }
              />
            ) : null}
          </div>
          <Formik
            enableReinitialize
            validateOnBlur={false}
            initialValues={{
              [sharedFormConstants.terms]: false,
              // [sharedFormConstants.cardOption]
              // isSavedPaymentSetForCustomer ?
              //   sharedFormConstants.cardOptionSaved
              //   : sharedFormConstants.cardOptionDebit,
            }}
            validationSchema={Yup.object().shape({
              [sharedFormConstants.terms]: Yup.boolean().oneOf(
                [true],
                t('termsAndConditionsRequired')
              ),
            })}
            onSubmit={(values) => {
              const newState = { ...state };
              setIsLoading(true);
              if (newState.selectedCard === sharedFormConstants.cardOptionSaved) {
                setSavedPaymentSetForCustomerSelected(true);
                api
                  .createSavedPaymentAutorenewSubscription({
                    VisibleId: userInfo.selectedCard.visibleId,
                    CustomerId: userInfo.customerId,
                    MediaId: userInfo.selectedCard.mediaId,
                    ProductId: state.product.ProductId,
                    RegFormOfPaymRecId: state.regFormOfPaymRecId,
                    PaymentGatewayResponse: null,
                    Month: state.autorenew.month,
                    Year: state.autorenew.year,
                  })
                  .then((result) => {
                    if (result.data.Success) {
                      toCheckoutStep2();
                      setIsLoading(false);
                    } else {
                      handleServerSuccessError(result.data.Error, setIsError);
                      setIsLoading(false);
                    }
                  })
                  .catch(() => {
                    setIsLoading(false);
                  });
              } else {
                handleMoneris();
              }
            }}
          >
            {({ errors, touched, handleSubmit, values }) => {
              const checkOption =
                values[sharedFormConstants.cardOption] === sharedFormConstants.cardOptionDebit;

              return (
                <form onSubmit={handleSubmit}>
                  <CardBlock
                    title={fields.paymentMethodTitle.value}
                    cssClass="cardComponent-top-margin"
                  >
                    <div
                      className="payment-text"
                      id="radio-group"
                      role="application"
                      aria-label={fields.paymentMethodDescriptionA11y.value}
                    >
                      <Text field={fields.paymentMethodDescription} />
                    </div>
                    <div className="payment-radio">
                      <RadioInput
                        label={fields.debitCardOptionLabel.value}
                        a11y={fields.debitCardOptionLabel.value}
                        name={sharedFormConstants.cardOption}
                        value={sharedFormConstants.cardOptionDebit}
                        onChange={(e) => {
                          setState({ ...state, selectedCard: e.target.value });
                        }}
                      />
                    </div>
                    {isSavedPaymentSetForCustomer && (
                      <div className="payment-radio">
                        {showCard &&
                          showCard.paymenMethodsList &&
                          showCard.paymenMethodsList.map((card, index) => {
                            if (card.AccountType === 1) {
                              return (
                                <div style={radioStyle}>
                                  <RadioInput
                                    label={`**** ${card.CreditCard.TruncatedPAN.slice(-4)}`}
                                    a11y={`**** ${card.CreditCard.TruncatedPAN.slice(-4)}`}
                                    name={sharedFormConstants.cardOption}
                                    value={sharedFormConstants.cardOptionSaved}
                                    onChange={(e) => {
                                      setState({
                                        ...state,
                                        selectedCard: e.target.value,
                                        regFormOfPaymRecId: card.RegFormOfPaymRecId,
                                      });
                                    }}
                                    cardImgSrc={getCardImage(card.CreditCard.CardType)}
                                    cardImgAlt="Media Icon"
                                    sm
                                  />
                                </div>
                              );
                            }
                            if (card.AccountType === 0) {
                              return (
                                <div style={radioStyle}>
                                  <RadioInput
                                    label={fields.authorizedLabel.value}
                                    a11y={fields.authorizedLabelA11y.value}
                                    name={sharedFormConstants.cardOption}
                                    value={sharedFormConstants.cardOptionSaved}
                                    onChange={(e) => {
                                      setState({
                                        ...state,
                                        selectedCard: e.target.value,
                                        regFormOfPaymRecId: card.RegFormOfPaymRecId,
                                      });
                                    }}
                                  />
                                </div>
                              );
                            }
                          })}
                      </div>
                    )}
                    <div className="checkout-terms-block">
                      <Placeholder
                        name="presto-terms-and-conditions"
                        rendering={getTermsAndConditions()}
                      />
                      {errors[sharedFormConstants.terms] && touched[sharedFormConstants.terms] && (
                        <InlineError cssClass="checkbox-error">
                          {errors[sharedFormConstants.terms]}
                        </InlineError>
                      )}
                    </div>
                    <div className="checkout-payment-total">
                      <Text field={fields.totalAmountLabel} />
                      <span>{formatDecimalWithCurrency(checkoutItem.subTotal)}</span>
                    </div>
                  </CardBlock>
                  {state.selectedCard === sharedFormConstants.cardOptionDebit ? (
                    <div className="checkout-subtext">
                      <Text field={fields.redirectText} />
                    </div>
                  ) : null}
                  <ButtonBlock>
                    <ButtonBlock right>
                      {state.selectedCard === sharedFormConstants.cardOptionDebit ? (
                        <Button type="submit" buttonTextA11y={fields.nextButtonTextA11y.value}>
                          <Text field={fields.nextButtonText} />
                        </Button>
                      ) : (
                        <Button type="submit" buttonTextA11y={fields.confirmButtonTextA11y.value}>
                          <Text field={fields.confirmButtonText} />
                        </Button>
                      )}
                      <Button
                        white
                        firstOrder
                        onClick={() => redirectToUrl(backToTransitPassesButtonLink)}
                        buttonTextA11y={fields.backButtonTextA11y.value}
                      >
                        <Text field={fields.backButtonText} />
                      </Button>
                    </ButtonBlock>
                  </ButtonBlock>
                </form>
              );
            }}
          </Formik>
        </div>
      )}

      {configKeys &&
        token &&
        configKeys
          .filter((item) => item.key === 'Cpg.Url')
          .map((configKey) => (
            <form
              method="POST"
              id="frmPayment"
              name="frmpayment"
              style={{ display: 'none' }}
              action={configKey.setting}
            >
              <input type="hidden" name="cc_crypt_type" value="7" id="inputCTID47" />
              <input type="hidden" name="cust_id" id="cust_id" value={userInfo.customerId} />
              <input type="hidden" name="rvarIsAx" value="true" id="inputCTID49" />
              <input type="hidden" name="rvarName" value="" id="inputCTID50" />
              <input type="hidden" name="lang" id="lang" value={getLongLanguageCode()} />
              <input type="hidden" name="LANGUAGE" id="LANGUAGE" value={getLongLanguageCode()} />
              <input type="hidden" name="paymentaggrement" id="paymentaggrement" value="" />
              <input type="hidden" name="nickname" id="nickname" value="" />
              <input
                type="hidden"
                name="ResponseURL"
                id="ResponseURL"
                value={`${getServerUrl()}/apidata/Autorenew/CreateAutorenewSubscription?visibleId=${userInfo.selectedCard.visibleId
                  }&mediaId=${userInfo.selectedCard.mediaId}&token=${token}&productId=${checkoutData.productId
                  }&year=${checkoutItem.autorenew.year}&month=${checkoutItem.autorenew.month
                  }&returnUrl=${returnUrl}&serializedData=${encodeURI(
                    JSON.stringify(checkoutData)
                  )}&deviceId=${userInfo.sessionInstanceId}&isTTCflow=${false}`}
              />
              <input type="hidden" name="isRegisteredPaymentMean" value="true" id="inputCTID56" />
            </form>
          ))}
    </>
  );
};

export default withTranslation()(SetAutorenew1);
