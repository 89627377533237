import { useDebugValue } from 'react';

import initialState from '../initialState';
import types from './types';
import names from '../../constants/shoppingCart';
import mediaSubTypes from '../../constants/mediaSubTypes';
import mediaTypes from '../../constants/mediaTypes';
import cardStatuses from '../../constants/cardStatuses';
import { sortNotifications } from '../../services/utils';

export function user(state = initialState, action = '') {
  switch (action.type) {
    case types.SET_TRANSIT_CHECKOUT_PAGE:
      return {
        ...state,
        checkoutTransitPage: action.payload,
      };
    case types.SET_SELECTED_CARD_DATA:
      return {
        ...state,
        selectedCardData: action.payload,
      };
    case types.SET_BALANCE_AUTOLOAD_DATA:
      return {
        ...state,
        autoLoadData: action.payload,
      };
    case types.SET_SHOPPING_SHIPPING_DETAILS:
      return {
        ...state,
        shoppingCartShippingDetails: action.payload,
      };
    case types.SET_NEW_CARD_DETAILS:
      return {
        ...state,
        newCardDetails: action.payload,
      };
    case types.SET_ORDER_CARD_RESPONSE:
      return {
        ...state,
        orderCardResponse: action.payload,
      };
    case types.SET_HOTLIST_API_SUCCESS:
      return {
        ...state,
        hotlistApiSuccess: action.payload,
      };
    case types.SET_PAYMENT_API_SUCCESS:
      return {
        ...state,
        paymentApiSuccess: action.payload,
      };
    case types.SET_DEACTIVATE_CARD_TYPE:
      return {
        ...state,
        deactivateCardType: action.payload,
      };
    case types.SET_SHOPPING_CART_ID:
      return {
        ...state,
        shoppingCartId: action.payload,
      };
    case types.SET_ADDRESS_TYPE:
      return {
        ...state,
        addressType: action.payload,
      };
    case types.LOGGED_IN_TIME:
      return {
        ...state,
        loggedInTime: action.payload.loggedInTime,
      };
    case types.LAST_VISITED_SECURED_ROUTE:
      return {
        ...state,
        lastVisitedSecuredRoute: action.payload.lastVisitedSecuredRoute,
      };
    case types.FIRST_NAME_PERSONAL_INFO:
      return {
        ...state,
        firstNameUpdatePersonalInfo: action.payload.firstNameUpdatePersonalInfo,
      };
    case types.LOGGED_OUT_TIME:
      return {
        ...state,
        logOutTime: action.payload.logOutTime,
      };
    case types.SET_NEW_SHIPPING_DETAILS:
      return {
        ...state,
        newShippingDetails: action.payload,
      };
    case types.SET_CURRENT_SHIPPING_DETAILS:
      return {
        ...state,
        currentShippingDetails: action.payload,
      };
    case types.CARD_REMOVED:
      return {
        ...state,
        isCardRemoved: action.payload.isCardRemoved,
      };
      case types.DELETE_ACCOUNT:
      return {
        ...state,
        isAccountDeleted: action.payload.isAccountDeleted,
      };
    case types.SET_WEAK_ACCOUNT:
    case types.CHANGE_STATUS:
      return { ...state, ...action.payload };
    case types.SET_CASL_POPUP_STATUS:
      return { ...state, showCaslPopUp: false };
    case types.SET_IS_POPUP_OPENED:
      return { ...state, isPopupOpened: true };
    case types.SET_IS_CARD_CLICKED:
      return { ...state, isCardClicked: action.payload.isCardClicked };
    case types.SET_USER_INFO: {
      const { Role } = action.payload;
      const { sub } = action.payload;
      if (Role === 'Registered') {
        const { Customer, Account } = action.payload;
        const { sub, ShowPopUp } = action.payload;
        const validFareMedias = Customer.FareMedias?.filter((fareMedia) => {
          return (
            fareMedia.CardStateCode !== cardStatuses.BlockedForReplacement &&
            fareMedia.CardStateCode !== cardStatuses.BlockedForTransfer
          );
        });
        return {
          ...state,
          customerId: Customer.Id,
          firstName: Customer.PersonalData.Name.FirstName || '',
          lastName: Customer.PersonalData.Name.LastName || '',
          userName: Customer.CustomerSecurity.Login,
          email: Customer.PersonalData?.EmailAddress,
          phoneNumber: Customer.PersonalData?.PhoneNumber,
          loginEmail: Customer.CustomerSecurity?.LoginEmail,
          emailVerificationStatus: Customer.EmailVerificationStatus,
          showCaslPopUp: ShowPopUp,
          isPopupOpened: false,
          accountId: Account ? Account.accountId : null,
          subject: sub,
          contactlessMedias:
            Account?.mediaInstances.map((media) => ({
              mediaId: media.mediaReferenceId,
              visibleId: media.mediaInstanceId,
              nickName: media.customName,
              type: mediaSubTypes[media.mediaSubType] || '',
              mediaType: mediaTypes[media.mediaType] || '',
              language: media.Language || '',
              dpan: media.dpan,
            })) || [],
          fareMedias: validFareMedias.map((item) => ({
            mediaId: item.MediaId,
            visibleId: item.VisibleId,
            ChildCustomerID: item.Customer.Id,
            nickName: item.NickName,
            currentBalance: null,
            tripDetails: item.TripDetails,
            cardStateCode: item.CardStateCode,
            type: mediaSubTypes.presto,
            language: item.Language || '',
            fareType: item.ProductConcession?.Name || '',
            specificFare: item.ProductConcession?.SpecificName || '',
            expiryDateValue: item?.ExpiryDate || '',
            endDate: item.ProductConcession?.EndDate || '',
            productConcessionExpiryDate: item?.ProductConcession?.ExpiryDate || '',
            isPrimary: item.Extension?.some(
              (ext) => ext.Field === 'IsPrimaryCard' && ext.Value === 'true'
            ),
            products: item.Products || [],
          })),
          // selectedCard: (() => {
          //   if (validFareMedias?.length) {
          //     return {
          //       mediaId: validFareMedias[0].MediaId || '',
          //       visibleId: validFareMedias[0].VisibleId || '',
          //       nickName: validFareMedias[0].NickName || '',
          //       type: mediaSubTypes.presto,
          //     };
          //   }
          //   if (Account?.mediaInstances && Account.mediaInstances.length) {
          //     return {
          //       mediaId: Account.mediaInstances[0].mediaReferenceId || '',
          //       visibleId: Account.mediaInstances[0].mediaInstanceId || '',
          //       nickName: Account.mediaInstances[0].customName || '',
          //       type: mediaSubTypes[Account.mediaInstances[0].mediaSubType] || '',
          //     };
          //   }

          //   return {
          //     mediaId: '',
          //     visibleId: '',
          //     nickName: '',
          //     type: '',
          //   };
          // })(),
        };
      }
      if (Role === 'Anonymous') {
        const { Customer, Faremedia, Account } = action.payload;
        const validFareMedias = Faremedia?.filter((fareMedia) => {
          return (
            fareMedia.CardStateCode !== cardStatuses.BlockedForReplacement &&
            fareMedia.CardStateCode !== cardStatuses.BlockedForTransfer
          );
        });
        return {
          ...state,
          customerId: Faremedia[0].Customer.Id,
          firstName: Faremedia[0].PersonalData?.Name.FirstName,
          userName: Customer?.CustomerSecurity.Login,
          email: Faremedia[0].PersonalData?.EmailAddress,
          loginEmail: Customer?.CustomerSecurity?.LoginEmail,
          emailVerificationStatus: Faremedia[0].EmailVerificationStatus,
          accountId: Account ? Account.accountId : null,
          contactlessMedias: [],
          fareMedias: validFareMedias.map((item) => ({
            mediaId: item.MediaId,
            visibleId: item.VisibleId,
            ChildCustomerID: item.Customer.Id,
            nickName: item.NickName,
            currentBalance: null,
            tripDetails: item.TripDetails,
            cardStateCode: item.CardStateCode,
            type: mediaSubTypes.anonymous,
            status: item.Status,
            registrationStatus: item.RegistrationStatus,
            language: item.Language || '',
            fareType: item.ProductConcession?.Name || '',
            specificFare: item.ProductConcession?.SpecificName || '',
            expiryDateValue: item?.ExpiryDate || '',
            endDate: item.ProductConcession?.EndDate || '',
            productConcessionExpiryDate: item?.ProductConcession?.ExpiryDate || '',
            isPrimary: item.Extension?.some(
              (ext) => ext.Field === 'IsPrimaryCard' && ext.Value === 'true'
            ),
            products: item.Products || [],
          })),
          selectedCard: (() => {
            if (validFareMedias?.length) {
              return {
                mediaId: validFareMedias[0].MediaId || '',
                visibleId: validFareMedias[0].VisibleId || '',
                nickName: validFareMedias[0].NickName || '',
                type: mediaSubTypes.anonymous,
                status: '',
              };
            }
            if (Account?.mediaInstances && Account.mediaInstances.length) {
              return {
                mediaId: Account.mediaInstances[0].mediaReferenceId || '',
                visibleId: Account.mediaInstances[0].mediaInstanceId || '',
                nickName: Account.mediaInstances[0].customName || '',
                type: mediaSubTypes[Account.mediaInstances[0].mediaSubType] || '',
                status: '',
              };
            }

            return {
              mediaId: '',
              visibleId: '',
              nickName: '',
              type: '',
              status: '',
            };
          })(),
        };
      }
    }
    case types.ANONYMOUS_ACCESS_TOKEN: {
      return {
        ...state,
        anonymousAccessToken: action.payload,
      };
    }
    case types.FETCH_ALL_DATA: {
      return {
        ...state,
        fetchAllDataSuccess: action.success,
      };
    }

    case types.SET_SHOPPING_CART: {
      const { OrderLines, TotalAmount, isNewItem } = action.payload;

      return {
        ...state,
        shoppingCartQuantity: OrderLines.length,
        shoppingCart: {
          ...state.shoppingCart,
          totalAmount: TotalAmount,
          isNewItem,
          items: OrderLines.map((item) => ({
            visibleId: item.FareMedia !== null ? item.FareMedia.VisibleId : 'NEWMEDIA',
            orderLineId: item.OrderLineId,
            id: item.Product.ProductId,
            name: item.Product.ProductName.replace(names.addMoney, names.funds),
            nickName: item.FareMedia !== null && item.FareMedia.NickName,
            productFamily: item.Product.ProductFamily,
            serviceProvider: item.Product.ProductServiceProvider.Id,
            quantity: item.Product.ProductName === names.addMoney ? '-' : item.Quantity,
            subTotal: item.SubTotal,
            validityStartDate: item.Product.ValidityStartDate,
            validityEndDate: item.Product.ValidityEndDate,
          })),
        },
      };
    }

    case types.ADD_SHOPPING_CART_MESSAGE: {
      const { message } = action.payload;
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          addMessage: [...state.shoppingCart.addMessage, message],
        },
      };
    }

    case types.EMPTY_SHOPPING_CART_MESSAGE: {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          addMessage: [],
        },
      };
    }

    case types.REMOVE_NEW_ITEM_FLAG:
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          isNewItem: false,
        },
      };

    case types.ITEMS_ARE_LOADING:
    case types.SET_SESSION_INSTANCEID:
    case types.REMOVE_ALL_FROM_SHOPPING_CART:
    case types.SHOW_SCREEN_HEADER:
    case types.SET_EMAIL_ERROR:
    case types.WEAK_PASSWORD_AND_QAA:
    case types.WEAK_PASSWORD:
    case types.WEAK_QAA:
    case types.SET_PENDING_PRODUCTS:
    case types.SELECT_CARD:
      return {
        ...state,
        ...action.payload,
      };
    case types.SELECT_CARD_REDIRECT:
      return {
        ...state,
        ...action.payload,
      };

    case types.SET_INITIAL_CARD:
      const { Role } = action.payload;
      if (Role === 'Registered') {
        const { Customer, Account } = action.payload;
        const validFareMedias = Customer.FareMedias?.filter((fareMedia) => {
          return (
            fareMedia.CardStateCode !== cardStatuses.BlockedForReplacement &&
            fareMedia.CardStateCode !== cardStatuses.BlockedForTransfer
          );
        });

        return {
          ...state,
          selectedCard: (() => {
            if (validFareMedias?.length) {
              return {
                mediaId: validFareMedias[0].MediaId || '',
                visibleId: validFareMedias[0].VisibleId || '',
                nickName: validFareMedias[0].NickName || '',
                type: mediaSubTypes.presto,
                status: '',
              };
            }
            if (Account?.mediaInstances && Account.mediaInstances.length) {
              return {
                mediaId: Account.mediaInstances[0].mediaReferenceId || '',
                visibleId: Account.mediaInstances[0].mediaInstanceId || '',
                nickName: Account.mediaInstances[0].customName || '',
                type: mediaSubTypes[Account.mediaInstances[0].mediaSubType] || '',
                status: '',
                mediaType: mediaTypes[Account.mediaInstances[0].mediaType] || '',
                language: Account.mediaInstances[0].Language || '',
                dpan: Account.mediaInstances[0].dpan,
              };
            }

            return {
              mediaId: '',
              visibleId: '',
              nickName: '',
              type: '',
              status: '',
            };
          })(),
        };
      }

    case types.SET_MEDIA_CURRENT_BALANCE: {
      return {
        ...state,
        fareMedias: state.fareMedias.map((media) => {
          if (media.visibleId === action.payload.visibleId) {
            return {
              ...media,
              currentBalance: action.payload.currentBalance,
            };
          }

          return media;
        }),
      };
    }

    case types.SET_MEDIA_NICK_NAME: {
      return {
        ...state,
        fareMedias: state.fareMedias.map((media) => {
          if (media.visibleId === action.payload.visibleId) {
            return {
              ...media,
              nickName: action.payload.NickName,
              isPrimary: action.payload.IsPrimary === true ? true : false,
            };
          }
          if (action.payload.IsPrimary === true) {
            return {
              ...media,
              isPrimary: false,
            };
          }

          return media;
        }),
      };
    }

    case types.SET_MEDIA_CURRENT_PASSES: {
      return {
        ...state,
        fareMedias: state.fareMedias.map((media) => {
          if (media.visibleId === action.payload.visibleId) {
            return {
              ...media,
              passes: action.payload.passes,
            };
          }

          return media;
        }),
      };
    }
    case types.SET_GOOGLE_WALLET_DATA: {
      return {
        ...state,
        contactlessMedias: state.contactlessMedias.map((media) => {
          if (media.dpan === action.payload.dpan) {
            return {
              ...media,
              ...action.payload,
            };
          }
          return media;
        }),
      };
    }

    case types.SET_MEDIA_NICK_NAME: {
      return {
        ...state,
        fareMedias: state.fareMedias.map((media) => {
          if (media.visibleId === action.payload.visibleId) {
            return {
              ...media,
              nickName: action.payload.NickName,
              isPrimary: action.payload.IsPrimary === true ? true : false,
            };
          }
          if (action.payload.IsPrimary === true) {
            return {
              ...media,
              isPrimary: false,
            };
          }

          return media;
        }),
      };
    }

    case types.SET_CONTACTLESS_MEDIA_NICK_NAME: {
      return {
        ...state,
        contactlessMedias: state.contactlessMedias.map((media) => {
          if (media.visibleId === action.payload.visibleId) {
            return {
              ...media,
              nickName: action.payload.NickName,
            };
          }
          return media;
        }),
      };
    }

    case types.SET_NOTIFICATION: {
      return {
        ...state,
        notifications: sortNotifications([...state.notifications, action.payload.notification]),
      };
    }
    case types.UPDATE_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (notification[action.payload.key] === action.payload.value) {
            return { ...notification, ...action.payload.updatedNotificationObj };
          }
          return notification;
        }),
      };
    }
    case types.UPDATE_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (notification[action.payload.key] === action.payload.value) {
            return { ...notification, ...action.payload.updatedNotificationObj };
          }
          return notification;
        }),
      };
    }

    case types.DISMISS_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications.map((notification, index) => {
          if (index === action.payload.index) {
            return { ...notification, isDismissed: true };
          }
          return notification;
        }),
      };
    }

    case types.SET_USER_PERSONAL_INFO: {
      return {
        ...state,
        managePersonalInfo: {
          ...state.managePersonalInfo,
          updatePersonalInfoEnabled: action.payload.updatePersonalInfoEnabled,
          userPersonalInfo: action.payload.userPersonalInfo,
          submittedInfo: action.payload.submittedInfo,
        },
      };
    }

    case types.SET_GUEST_USER_ENABLED: {
      return {
        ...state,
        guestUserInfoEnabled: {
          ...state.guestUserInfoEnabled,
          isGuestLogin: action.payload.isGuestLogin,
        },
      };
    }

    case types.SET_GUEST_SIGN_POPUP_ENABLED: {
      return {
        ...state,
        guestSignPopupEnabled: {
          ...state.guestSignPopupEnabled,
          enablePopup: action.payload.enablePopup,
        },
      };
    }

    case types.SET_GUEST_USER_CHECKOUT: {
      return {
        ...state,
        guestuserCheckoutEnabled: {
          ...state.guestuserCheckoutEnabled,
          continueCheckout: action.payload.continueCheckout,
        },
      };
    }

    case types.SET_USER_SIGNED_FROM_GUEST_FLOW:
      return {
        ...state,
        userSignedFromGuestFlow: action.payload,
      };

    case types.SET_USER_SIGNED_FROM_CREATE_ACCOUNT:
      return {
        ...state,
        userSignedFromCreateAccount: action.payload,
      };

    case types.SET_GUEST_SIGN_POPUP_ENABLED_FROM_ORDERCARD:
      return {
        ...state,
        guestsiginPopupfromOrderCard: action.payload,
      };

    case types.SET_GUEST_SHOPPING_CART_TOKEN:
      return {
        ...state,
        guestShoppingCartToken: action.payload,
      };

    case types.GO_BACK_URL:
      return {
        ...state,
        goBackURL: action.payload,
      };

    case types.TTC_CHECKOUT_VALUES:
      return {
        ...state,
        ttcCheckoutValues: action.payload,
      };

    case types.SET_GUEST_SHOPPING_CART_PAYLOAD:
      return {
        ...state,
        guestShoppingCartPayload: action.payload,
      };
    case types.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };
    case types.UPDATE_LOGIN_AND_SECURITY_FIELDS: {
      return {
        ...state,
        loginAndSecurity: { ...state.loginAndSecurity, ...action.payload },
      };
    }
    case types.SET_B2C_CLAIMS_RESPONSE: {
      return {
        ...state,
        b2cClaimsResponse: { ...action.payload },
      };
    }
    default:
      return state;
  }
}
